// TODO: This file is temporal and will be removed when the data is fetched from the back-end

export const dbIssuersList = [
  {
    id        : 1,
    shortname : 'Bank of America',
    fullname  : 'Bank of America Corporation',
  },
  {
    id        : 2,
    shortname : 'Barclays',
    fullname  : 'Barclays Bank PLC',
  },
  {
    id        : 3,
    shortname : 'BBVA',
    fullname  : 'BBVA Global Markets B.V.',
  },
  {
    id        : 4,
    shortname : 'BNP Paribas',
    fullname  : 'BNP Paribas Issuance B.V.',
  },
  {
    id        : 5,
    shortname : 'CIBC',
    fullname  : 'Canadian Imperial Bank of Commerce (CIBC)',
  },
  {
    id        : 6,
    shortname : 'Citibank',
    fullname  : 'Citigroup Global Markets Holdings Inc.',
  },
  {
    id        : 7,
    shortname : 'EFG',
    fullname  : 'EFG International Finance (Guernsey) Ltd.',
  },
  {
    id        : 8,
    shortname : 'Goldman Sachs Group',
    fullname  : 'Goldman Sachs Group',
  },
  {
    id        : 9,
    shortname : 'Goldman Sachs F.C.I.L.',
    fullname  : 'Goldman Sachs Finance Corp International Ltd',
  },
  {
    id        : 10,
    shortname : 'Goldman Sachs International',
    fullname  : 'Goldman Sachs International',
  },
  {
    id        : 11,
    shortname : 'Leonteq',
    fullname  : 'Leonteq Securities AG, Guernsey Branch, St. Peter Port, Guernsey',
  },
  {
    id        : 12,
    shortname : 'Marex Financial',
    fullname  : 'Marex Financial',
  },
  {
    id        : 13,
    shortname : 'Marex Group',
    fullname  : 'Marex Group',
  },
  {
    id        : 14,
    shortname : 'Morgan Stanley',
    fullname  : 'Morgan Stanley',
  },
  {
    id        : 15,
    shortname : 'Morgan Stanley I.P.',
    fullname  : 'Morgan Stanley & Co. International PLC',
  },
  {
    id        : 16,
    shortname : 'Raiffeisen',
    fullname  : 'Raiffeisen Schweiz Genossenschaft',
  },
  {
    id        : 17,
    shortname : 'Royal Bank of Canada',
    fullname  : 'Royal Bank of Canada',
  },
  {
    id        : 18,
    shortname : 'Societe General',
    fullname  : 'Societe Generale SA',
  },
  {
    id        : 19,
    shortname : 'UBS',
    fullname  : 'UBS AG',
  },
];

export const dbProductSubTypeMapping : { [key : string] : string[] } = {
  Autocall             : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win'],
  Digital              : ['Reverse Convertible', 'Classic'],
  'Credit Linked Note' : ['Tranche KG', 'Single Name', 'Tranche'],
  Participation        : ['2 Options Payoff', 'Shark Note', 'Booster/Bonus', 'Twin Win'],
  Rates                : ['Fixed Rate Note', 'Floating Rate Note', 'Floored Floater', 'TARN Steepner'],
  Custom               : ['Custom'],
};


export const mockPlaces = ['UQ', 'NYSE', 'NASDAQ', 'LSE'];

export const mockTickers = ['AAPL', 'MSFT', 'TSLA', 'GOOGL', 'AMZN', 'META', 'NVDA', 'AMC'];

export const dbCurrencyData = [
  {
    id     : 3,
    code   : 'AUD',
    symbol : 'A$',
    name   : 'Australian dollar',
  },
  {
    id     : 8,
    code   : 'BRL',
    symbol : 'R$',
    name   : 'Brazilian real',
  },
  {
    id     : 6,
    code   : 'CAD',
    symbol : 'CA$',
    name   : 'Canadian dollar',
  },
  {
    id     : 10,
    code   : 'INR',
    symbol : '₹',
    name   : 'Indian rupee',
  },
  {
    id     : 9,
    code   : 'ILS',
    symbol : '₪',
    name   : 'Israeli new shekel',
  },
  {
    id     : 7,
    code   : 'JPY',
    symbol : '¥',
    name   : 'Japanese yen',
  },
  {
    id     : 4,
    code   : 'CHF',
    symbol : 'CHF',
    name   : 'Swiss franc',
  },
  {
    id     : 5,
    code   : 'GBP',
    symbol : '£',
    name   : 'Pound sterling',
  },
  {
    id     : 2,
    code   : 'USD',
    symbol : '$',
    name   : 'United States dollar',
  },
  {
    id     : 35,
    code   : 'EUR',
    symbol : '€',
    name   : 'Euro',
  },
];

