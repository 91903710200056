import './ProductEventManager.scss'
import { Button, Flex, Select, TableColumnsType, InputNumber } from 'antd'
import { useState } from 'react'
import { FilePdfOutlined } from '@ant-design/icons'
import { TermSheetViewer } from './TermsheetViewer'
import { CustomTable } from '../../../Shared/CustomTable/CustomTable'
import { ProductEventType } from '../../../data/ProductType'
import { productEventsList } from './data'
import dayjs from 'dayjs'

const ProductEventManager = () => {
  const [products, setProducts] = useState<ProductEventType[]>(productEventsList)
  const [open, setOpen] = useState(false)

  const onChangeValue = (value: number, isin: string, key: keyof ProductEventType) => {
    setProducts((prev) => prev.map((p) => (p.isin === isin ? { ...p, [key]: value } : p)))
  }

  const columns: TableColumnsType<ProductEventType> = [
    {
      title: 'ISIN',
      dataIndex: 'isin',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 260,
      sorter: (left, right) => left.name.localeCompare(right.name),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      sorter: (left, right) => new Date(left.date).getTime() - new Date(right.date).getTime(),
      defaultSortOrder: 'ascend',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Potential flow',
      dataIndex: 'potentialFlow',
      sorter: (left, right) => left.potentialFlow - right.potentialFlow,
      align: 'center',
      render: (value) => <span>{value}%</span>,
    },
    {
      title: 'Termsheet',
      dataIndex: 'termsheet',
      align: 'center',
      render: () => (
        <Button type="dashed" onClick={() => setOpen(true)}>
          <FilePdfOutlined />
          <span>View Termsheet</span>
        </Button>
      ),
    },
    {
      title: 'Enter actual flow',
      dataIndex: 'actualFlow',
      align: 'center',
      sorter: (left, right) => left.actualFlow - right.actualFlow,
      render: (value, record) => (
        <Flex justify="center" vertical align="center">
          <InputNumber
            className="actual__flow__input"
            min={0}
            step={0.1}
            value={value}
            onChange={(value) => onChangeValue(value, record.isin, 'actualFlow')}
            suffix="%"
          />
        </Flex>
      ),
    },
    {
      title: 'Status Change',
      dataIndex: 'status',
      align: 'center',
      render: (value, record) => (
        <Select
          value={value}
          className={`status__select ${record.status === 'Alive' ? 'green_positive' : 'red'}`}
          onChange={(value) => onChangeValue(value, record.isin, 'status')}
          options={[
            { value: 'Alive', label: 'Alive' },
            { value: 'Dead', label: 'Dead' },
          ]}
          style={{ width: 100 }}
        />
      ),
      filters: ['Alive', 'Dead'].map((t) => ({ text: t, value: t })),
      onFilter: (value, record) => record.status.includes(value as string),
    },
    {
      title: '',
      dataIndex: 'validate',
      align: 'center',
      render: () => <Button type="primary">Validate</Button>,
    },
  ]

  return (
    <>
      <TermSheetViewer open={open} onClose={() => setOpen(false)} />
      <div className="event_manager_container">
        <CustomTable<ProductEventType>
          tableName="RODUCTS EVENT MANAGER"
          columns={columns}
          dataList={products}
          colsTopApplyDateFilter={['date']}
          colsTopApplySeachFilter={['name']}
        />
      </div>
    </>
  )
}

export { ProductEventManager }
