import dayjs from 'dayjs';

import type {ProductListItem} from '../../../data/ProductType';

export type ProductGroupedByAttribute = {
  id        : string,
  groupName : string,
  products  : ProductListItem[],
};

export type ValueRangeGroupType = {
  id        : string,
  groupName : string,
  filter    : (v : ProductListItem, attribute : keyof ProductListItem)=> boolean,
};

  type DateGroup = {
    id        : string,
    groupName : string,
    range     : [dayjs.Dayjs, dayjs.Dayjs, boolean, boolean],
  };

export const valorisation105 = 1.05;
export const valorisation100 = 1;
export const valorisation90 = 0.9;

export const valorizationGroups : ValueRangeGroupType[] = [
  {
    id        : '1',
    groupName : 'Above 105%',
    filter    : (v, attribute) : boolean => v[attribute] as number >= valorisation105,
  },
  {
    id        : '2',
    groupName : 'Between 100% and 105%',
    filter    : (v, attribute) : boolean => v[attribute] as number >= valorisation100 && v[attribute] as number < valorisation105,
  },
  {
    id        : '3',
    groupName : 'Between 90% and 100%',
    filter    : (v, attribute) : boolean => v[attribute] as number >= valorisation90 && v[attribute] as number < valorisation100,
  },
  {
    id        : '4',
    groupName : 'Below 90%',
    filter    : (v, attribute) : boolean => v[attribute] as number < valorisation90,
  },
];

export const filterByDate = (date : string, groupRange : DateGroup['range']) : boolean => {
  const [start, end, includeLeft, includeRight] = groupRange;
  const filterParam : '[)' | '()' | '[]' | '(]' = `${includeLeft ? '[' : '('}${includeRight ? ']' : ')'}`;

  return dayjs(date).isBetween(start, end, 'day', filterParam);
};

export const createDateGroups = () : DateGroup[] => {
  let leftLimit = dayjs();
  let rightLimit = dayjs().add(7, 'day');

  const groups : Pick<DateGroup, 'groupName' | 'range'>[] = [
    {
      groupName : 'Before today',
      range     : [dayjs('1950-01-01'), leftLimit, true, false],
    },
    {
      groupName : 'Next 7 days',
      range     : [leftLimit, rightLimit, true, true],
    },
  ];

  leftLimit = rightLimit.add(1, 'day');
  rightLimit = leftLimit.add(23, 'day');

  groups.push({
    groupName : 'Next 30 days',
    range     : [leftLimit, rightLimit, false, true],
  });

  leftLimit = rightLimit.add(1, 'day');
  rightLimit = leftLimit.endOf('month');

  groups.push({
    groupName : rightLimit.format('MMMM YYYY'),
    range     : [leftLimit, rightLimit, false, true],
  });

  leftLimit = rightLimit.add(1, 'day');

  // Add month groups for the next 6 months
  for (let i = 1; i <= 6; i++) {
    rightLimit = leftLimit.endOf('month');
    groups.push({
      groupName : leftLimit.format('MMMM YYYY').toString(),
      range     : [leftLimit, rightLimit, true, true],
    });
    leftLimit = rightLimit.add(1, 'month').startOf('month');
  }

  leftLimit = rightLimit.add(1, 'day').startOf('month');
  rightLimit = leftLimit.endOf('year');

  // Group for dates beyond 6 months until the end of the current year
  groups.push({
    groupName : leftLimit.year().toString(),
    range     : [leftLimit, rightLimit, true, true],
  });

  leftLimit = rightLimit.add(1, 'year').startOf('year');

  // Groups for the next 5 years
  for (let j = 1; j <= 5; j++) {
    rightLimit = leftLimit.endOf('year');
    groups.push({
      groupName : leftLimit.year().toString(),
      range     : [leftLimit, rightLimit, true, true],
    });
    leftLimit = rightLimit.add(1, 'year').startOf('year');
  }

  leftLimit = rightLimit;
  rightLimit = dayjs('2200-01-01');

  // Beyond 5 years
  groups.push({
    groupName : `Beyond ${leftLimit.format('YYYY')}`,
    range     : [leftLimit, rightLimit, false, true],
  });

  return groups.map((group, index) => ({
    id : (index + 1).toString(),
    ...group,
  }));
};


export const dateGroups = createDateGroups();

export const groupByValueRange = (list : ProductListItem[], attribute : keyof ProductListItem) :
ProductGroupedByAttribute[] => valorizationGroups.map(({
  id, groupName, filter: groupFilter,
}) => ({
  id,
  groupName,
  products : list.filter((item) => groupFilter(item, attribute)),
})).filter((group) => group.products.length > 0);

export const groupByEventDate = (list : ProductListItem[], attribute : keyof ProductListItem) : ProductGroupedByAttribute[] => dateGroups.map(({
  id, groupName, range,
}) => ({
  id,
  groupName,
  products : list.filter((item) => filterByDate(item[attribute] as string, range)),
})).filter((group) => group.products.length > 0);


export const groupByAttribute = (list : ProductListItem[], attribute : keyof ProductListItem) : ProductGroupedByAttribute[] => Object.values(
  // eslint-disable-next-line @stylistic/block-spacing
  list.reduce((acc : {[key : string] : ProductGroupedByAttribute}, val : ProductListItem) => {
    const groupstring : string = val[attribute].toString();
    acc[groupstring] = acc[groupstring] ?? {
      id        : groupstring,
      groupName : groupstring,
      products  : [],
    };
    acc[groupstring].products.push(val);
    return acc;
  }, {})
);
