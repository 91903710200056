import React from 'react'
import { Modal } from 'antd'

const TermSheetViewer: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => (
  <Modal
    title="TERMSHEET"
    centered
    open={open}
    destroyOnClose={true}
    onOk={onClose}
    onCancel={onClose}
    okText="Close"
    width={1000}
    footer={(_, { OkBtn }) => <OkBtn />}
  >
    <iframe style={{ border: 'none', minHeight: '80vh' }} src="https://arxiv.org/pdf/2307.06435" width="100%" />
  </Modal>
)

export { TermSheetViewer }
