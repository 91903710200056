import {Timeline} from 'antd';

import dayjs from 'dayjs';

import {
  goldenYellow, greyColor, whiteColor
} from '../../styles/colors';

import {CalendarEventCard} from './CalendarEventCard';

import type {ProductEvent} from '../../data/ProductType';

import './CalendarEvents.scss';

type CalendarEventsPropsType = {
  events          : ProductEvent[],
  editionMode ?   : boolean,
  onChangeEvent ? : (eventIndex : number, action : 'edit' | 'remove')=> void,
};

const CalendarEvents = ({
  events, editionMode, onChangeEvent,
} : CalendarEventsPropsType) : React.ReactNode => {

  const getNextEvent = () : ProductEvent | null => {
    const currentDate = dayjs();
    const filteredEvents = events.filter((event) => dayjs(event.valuationDate).isAfter(currentDate));
    return filteredEvents.length > 0 ? filteredEvents[0] : null;
  };

  const getStepColor = ({
    isBeforeNextEvent, isTodayEvent,
  } : { isBeforeNextEvent : boolean,
    isTodayEvent         : boolean, }) : string => {
    if (isTodayEvent) {
      return goldenYellow;
    }
    if (isBeforeNextEvent) {
      return greyColor;
    }
    return whiteColor;
  };

  const nextEvent = getNextEvent();
  const today = dayjs().format('YYYY-MM-DD');

  const sortedEvents = events.sort((left, right) => new Date(left.valuationDate).getTime() - new Date(right.valuationDate).getTime());

  return (
    <Timeline
      className = {'product__timeline'}
      mode = {'alternate'}
      items = {
        sortedEvents.map((event, index) => {
          const isBeforeNextEvent = nextEvent ? dayjs(event.valuationDate).isBefore(dayjs(nextEvent.valuationDate)) : false;
          const isNextEvent = nextEvent?.valuationDate === event.valuationDate && nextEvent.eventType === event.eventType;
          const isTodayEvent = today === dayjs(event.valuationDate).format('YYYY-MM-DD');
          return {
            label : !isTodayEvent && (
              <label className = {`header__label ${isBeforeNextEvent && 'header__label__passed'}`}>
                {dayjs(event.valuationDate).format('DD/MM/YYYY')}
              </label>
            ),
            children :
            (<CalendarEventCard
              editionMode = {editionMode}
              event = {event}
              eventIndex = {index}
              className = {'single_event'}
              isPastEvent = {isBeforeNextEvent}
              isToday = {isTodayEvent}
              isNextEvent = {isNextEvent}
              onChangeEvent = {onChangeEvent}
            />),
            position  : isTodayEvent ? 'right' : 'left',
            className : isTodayEvent ? 'today-event' : '',
            color     : getStepColor({
              isBeforeNextEvent,
              isTodayEvent,
            }),
          };
        })
      }
    />
  );
};

export {CalendarEvents};
