import {useEffect, useState} from 'react';
import {
  Outlet,
  matchRoutes, useLocation, useMatches, useNavigate
} from 'react-router-dom';

import type {MenuProps} from 'antd';
import {
  Layout, Menu
} from 'antd';
import {Content, Header} from 'antd/es/layout/layout';

import Image from '../../assets/bg3.png';
import Logo from '../../assets/logo.svg';
import {keycloakInstance} from '../../config/keycloak';
import {
  CreateProductPageRoute,
  EditProductPageRoute,
  ExplorerRoute,
  HomeRoute, PricingRoute, ProductEventManagerRoute, ProductPageRoute, ProductsRoute, TradePageRoute, TradesListRoute
} from '../../data/Routes';
import {HeaderTabsEnum} from '../../data/enums/header';
import {expertGreen} from '../../styles/colors';
import {MainTitle} from '../MainTitle/MainTitle';

import type {KeycloakProfile} from './../../../node_modules/keycloak-js/lib/keycloak';

import './__styles__/styles.css';


export const AppLayout = () : React.JSX.Element => {
  const [currentTab, setCurrentTab] = useState<HeaderTabsEnum | null>(null);
  const [username, setUserName] = useState<string>('');

  const navigate = useNavigate();
  const matches = useMatches();
  const currentLocation = useLocation();

  keycloakInstance.loadUserProfile()
    .then((d : KeycloakProfile) => {
      if (!d.username) {
        throw new Error('No username');
      }
      setUserName(d.username);
    })
    .catch((rej : unknown) => console.warn('keycloakInstance.loadUserProfile(): ', rej));

  useEffect(() => {
    if (matches.length <= 1) {
      setCurrentTab(HomeRoute.tab);
    } else {
      switch (matches[1].pathname) {
        case PricingRoute.path:
          setCurrentTab(PricingRoute.tab);
          break;
        case ProductsRoute.path:
          setCurrentTab(ProductsRoute.tab);
          break;
        case TradesListRoute.path:
          setCurrentTab(TradesListRoute.tab);
          break;
        case ProductEventManagerRoute.path:
          setCurrentTab(ProductEventManagerRoute.tab);
          break;
        default:
          break;
      }
    }
  }, [matches]);

  const handleHeaderTabClick : MenuProps['onClick'] = (e) : void => {
    if (!Object.values(HeaderTabsEnum).includes(e.key as HeaderTabsEnum)) {
      console.error('Unkown header tab value: ', e.key);
      return;
    }

    const headerTab : HeaderTabsEnum = e.key as HeaderTabsEnum;
    switch (headerTab) {
      case HeaderTabsEnum.ayDeal:
        navigate(PricingRoute.path);
        break;
      case HeaderTabsEnum.products:
        navigate(ProductsRoute.path);
        break;
      case HeaderTabsEnum.tradesList:
        navigate(TradesListRoute.path);
        break;
      case HeaderTabsEnum.eventsManager:
        navigate(ProductEventManagerRoute.path);
        break;
      case HeaderTabsEnum.explorer:
        window.location.href = ExplorerRoute.path;
        break;
      default:
    }
  };

  const items = Object.values(HeaderTabsEnum).map((e) => ({
    key   : e,
    label : e,
  }));

  const routesToCheck = [
    {path : PricingRoute.path},
    {path : CreateProductPageRoute.path},
    {path : EditProductPageRoute.path},
    {path : ProductPageRoute.path},
    {path : TradePageRoute.path},
  ];
  const match = matchRoutes(routesToCheck, currentLocation);

  return (
    <Layout>
      <Header
        className = {'topnav'}
        style = {{
          display         : 'flex',
          backgroundColor : expertGreen,
        }}
      >
        <img
          className = {'logo'}
          src = {Logo}
          alt = {'Shape Logo'}
          onClick = {() : void => {
            navigate(HomeRoute.path);
          }}
        />

        <Menu
          selectedKeys = {[currentTab ?? '']}
          mode = {'horizontal'}
          items = {items}
          style = {{
            flex            : 1,
            minWidth        : 0,
            backgroundColor : expertGreen,
            border          : 'unset',
          }}
          onClick = {handleHeaderTabClick}
        />

        <span>{username}</span>
      </Header>

      <Layout>
        <Content
          style = {{
            padding              : 24,
            margin               : 0,
            minHeight            : 'calc(100vh - 64px)', // Subtract header height
            backgroundImage      : `url(${Image})`,
            backgroundSize       : 'cover',
            overflow             : 'hidden',
            backgroundAttachment : 'fixed',
          }}
        >
          {!match
          && <MainTitle text = {currentTab ?? 'Main'} />}

          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
