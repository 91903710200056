import {useEffect} from 'react';

import dayjs from 'dayjs';

import {CustomProductAttrEnum} from '../../../data/enums/lifecycle';
import {
  DAYS_IN_MONTH, DAYS_IN_QUARTER, DAYS_IN_SEMESTER, DAYS_IN_YEAR,
  HOURS_IN_DAY, MILLISECONDS_IN_SECOND, MINUTES_IN_HOUR
} from '../../../data/time';

import type {ProductEvent} from '../../../data/ProductType';

// Automatically create events based on the event frequency and maturity
const useAutoCreateEvent = ({
  maturity, frequency, calendarEvents, setCalendarEvents,
} : {
  maturity          : string,
  frequency         : string,
  calendarEvents    : ProductEvent[],
  setCalendarEvents : (events : ProductEvent[])=> void,
}) : void => {

  const makeCustomEvent = (dateDifference : number, freq : number) : ProductEvent[] => {
    const today = new Date();
    const events = [];
    for (let i = 1; i < dateDifference / freq; i++) {
      const newDate = new Date(today.getTime() + (i * freq * HOURS_IN_DAY * MINUTES_IN_HOUR * MINUTES_IN_HOUR * MILLISECONDS_IN_SECOND));
      events.push({
        id            : i,
        paymentDate   : dayjs(newDate).format('YYYY-MM-DD'),
        valuationDate : dayjs(newDate).format('YYYY-MM-DD'),
        eventType     : CustomProductAttrEnum.ToSet.toString(),
        outcomeValue  : '',
        status        : CustomProductAttrEnum.ToSet.toString(),
      });
    }
    return events;
  };

  const populateOtherEvents = () : void => {
    const today = new Date();
    const maturityDate = new Date(maturity);
    const dateDifference = (maturityDate.getTime() - today.getTime()) / (MILLISECONDS_IN_SECOND * MINUTES_IN_HOUR * MINUTES_IN_HOUR * HOURS_IN_DAY);
    let events : ProductEvent[];

    switch (frequency) {
      case 'Annual':
        events = makeCustomEvent(dateDifference, DAYS_IN_YEAR);
        break;
      case 'Semi-Annual':
        events = makeCustomEvent(dateDifference, DAYS_IN_SEMESTER);
        break;
      case 'Quarterly':
        events = makeCustomEvent(dateDifference, DAYS_IN_QUARTER);
        break;
      case 'Monthly':
        events = makeCustomEvent(dateDifference, DAYS_IN_MONTH);
        break;
      default:
        events = [];
        break;
    }

    // Remove 'To Set' existing events and add new ones
    setCalendarEvents([...calendarEvents.filter((event) => event.eventType !== CustomProductAttrEnum.ToSet.toString()), ...events]);
  };

  useEffect(() => {
    populateOtherEvents();
  }, [maturity, frequency]);
};

export {useAutoCreateEvent};
