// TODO: This file is temporal and will be removed when the data is fetched from the back-end

import custodiansJsonData from './custodians.json';
import productsJsonData from './products.json';
import tradesJsonData from './trades.json';

import type {ProductListItem} from '../../../data/ProductType';
import type {TradeDataType} from '../../../data/TradesTypes';
import type {CurrencyNameType} from './../../../data/currency';


const tradesData : unknown = tradesJsonData;
const productsData : unknown = productsJsonData;
const custodiansData : unknown = custodiansJsonData;

let tradesListData = tradesData as TradeDataType[];
let productsListData = productsData as ProductListItem[];
const custodiansList = custodiansData as {id : number,
  name                                        : string, }[];


tradesListData = tradesListData.map((trade, index) => {
  const product = productsListData.find((p) => p.isin === trade.isin);
  if (product) {
    trade.productName = product.name;
  }

  trade.custodians = trade.custodians.map((cus) => {
    const custodian = custodiansList.find((c) => c.id === cus.id);
    if (custodian) {
      cus.name = custodian.name;
    } else {
      cus.name = 'UBS (France) SA';
    }
    return cus;
  }).filter((c) => c.name);
  trade.id = index;
  trade.currency = productsListData.find((p) => p.isin === trade.isin)?.currency.trim() as CurrencyNameType;
  trade.issuer = productsListData.find((p) => p.isin === trade.isin)?.issuerShortName as string || '';
  return trade;
});

productsListData = productsListData.map((p) => {
  const trades = tradesListData.filter((trade) => trade.isin === p.isin);
  return {
    ...p,
    currency : p.currency.trim() as CurrencyNameType,
    trades   : trades.length > 0 ? trades : [tradesListData[0]],
  };
}).sort((left, right) => new Date(left.nextEventDate).getTime() - new Date(right.nextEventDate).getTime());

const clientsList = ['All', ...Array.from(new Set(tradesListData.map((c) => c.clientName)))];

const tradingEntities = [...Array.from(new Set(tradesListData.map((c) => c.tradingEntity)))];


export {clientsList, productsListData, tradesListData, tradingEntities};
