import {valorisation100, valorisation105, valorisation90} from '../../ProductsList/utils';

import './ProductValuation.scss';

type ProductValorisationPropsType = {
  valorisationValue : number,
  // eslint-disable-next-line @stylistic/type-annotation-spacing
  prefixText      ? : string,
  // eslint-disable-next-line @stylistic/type-annotation-spacing
  style           ? : React.CSSProperties,
};

const mapValorisationToClass = [
  {
    threshold : valorisation105,
    color     : 'green_positive',
  },
  {
    threshold : valorisation100,
    color     : 'green_normal',
  },
  {
    threshold : valorisation90,
    color     : 'orange',
  },
];

export const ProductValorisation = ({
  valorisationValue,
  prefixText,
  style,
} : ProductValorisationPropsType) : React.ReactNode => {
  const className = mapValorisationToClass.find((c) => c.threshold <= valorisationValue)?.color ?? 'red';

  return (
    <label
      className = {`valorisation ${className}`}
      style = {style}
    >
      {`${prefixText ?? ''} ${(valorisationValue * 100).toFixed(2).toString()}%`}
    </label>
  );
};
