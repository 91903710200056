import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  CaretDownOutlined, CaretUpOutlined,
  DeleteFilled, EditFilled
} from '@ant-design/icons';
import type {TableColumnsType} from 'antd';
import {
  Button, Checkbox, Col, Flex, Modal, Row, Select, Space, Switch, Table
} from 'antd';

import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import {CustomTable} from '../../../Shared/CustomTable/CustomTable';
import {TradeStatusClass} from '../../../data/enums/lifecycle';
import {parseCurrency} from '../../../utils/currencyparser';
import {clientsList, tradesListData, tradingEntities} from '../mockData/mockData';

import type {TradeDataType, TradeDetailsType} from '../../../data/TradesTypes';
import type {CurrencyNameType} from '../../../data/currency';

import './TradesList.scss';

const ITEMS_PER_PAGE = 30;

const TradeList = () : React.ReactNode => {
  const [selectedClient, setSelectedClient] = useState('All');
  const [tradesList, setTradesList] = useState(tradesListData);
  const [hiddenInfos, setHiddenInfos] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClientFilter = (filterValue : string) : void => {
    setSelectedClient(filterValue);
    if (filterValue === 'All') {
      setTradesList(tradesListData);
      return;
    }
    const filteredList = tradesListData.filter((c) => c.clientName.includes(filterValue));
    setTradesList(filteredList);
  };

  const columns : TableColumnsType<TradeDataType> = [
    {
      title     : 'ISIN',
      dataIndex : 'isin',
      width     : 120,
    },
    {
      title     : 'Product Name',
      dataIndex : 'productName',
      width     : 320,
      sorter    : (left, right) : number => left.productName.localeCompare(right.productName),
    },
    {
      title     : 'Transaction Date',
      dataIndex : 'transactionDate',
      align     : 'center',
      width     : 180,
      sorter    : (left, right) : number => new Date(left.transactionDate).getTime() - new Date(right.transactionDate).getTime(),
      render    : (renderValue : string) : string => dayjs(renderValue).format('DD/MM/YYYY'),
    },
    {
      title     : 'Issue Date',
      dataIndex : 'issueDate',
      align     : 'center',
      width     : 140,
      sorter    : (left, right) : number => new Date(left.issueDate).getTime() - new Date(right.issueDate).getTime(),
      render    : (renderValue : string) : string => dayjs(renderValue).format('DD/MM/YYYY'),
    },
    {
      title     : 'Nominal',
      dataIndex : 'nominal',
      align     : 'center',
      width     : 170,
      sorter    : (left, right) : number => left.nominal - right.nominal,
      render    : (renderValue : number, record) : string => parseCurrency(renderValue, record.currency),
    },
    {
      title     : 'Client',
      dataIndex : 'clientName',
      width     : 200,
      sorter    : (left, right) : number => left.clientName.localeCompare(right.clientName),
    },
    {
      title     : 'Trading Entity',
      dataIndex : 'tradingEntity',
      width     : 200,
      sorter    : (left, right) : number => left.tradingEntity.localeCompare(right.tradingEntity),
      filters   : tradingEntities.map((t) => ({
        text  : t,
        // eslint-disable-next-line id-denylist
        value : t,
      })),
      onFilter     : (filterValue, record) : boolean => record.tradingEntity.startsWith(filterValue as string),
      filterSearch : true,
    },
    {
      title     : 'Total Commission',
      dataIndex : 'totalCommission',
      align     : 'center',
      width     : 150,
      sorter    : (left, right) : number => left.totalCommission - right.totalCommission,
      render    : (renderValue : number, record) : string => parseCurrency(renderValue, record.currency),
    },
    {
      title     : 'Aydo Commission',
      dataIndex : 'aydoCommision',
      align     : 'center',
      width     : 150,
      sorter    : (left, right) : number => left.aydoCommision - right.aydoCommision,
      render    : (renderValue : number, record) : string => parseCurrency(renderValue, record.currency),
    },
    {
      title     : 'Status',
      dataIndex : 'status',
      align     : 'center',
      width     : 140,
      render    : (valueValue : string, record) : React.ReactNode => (
        <Select
          style = {{width : 120}}
          value = {valueValue}
          disabled = {hiddenInfos}
          className = {`status__select ${TradeStatusClass[record.status as keyof typeof TradeStatusClass]}`.trim()}
          options = {Object.keys(TradeStatusClass).map((t) => ({
            title : t,
            // eslint-disable-next-line id-denylist
            value : t,
          }))}
          onClick = {(e) : void => e.stopPropagation()}
          onChange = {(val : string) : void => {
            setTradesList(
              tradesList.map((t) => {
                if (t.id === record.id) {
                  t.status = val;
                }
                return t;
              })
            );
          }}
        />
      ),
      filters : Object.keys(TradeStatusClass).map((t) => ({
        text  : t,
        // eslint-disable-next-line id-denylist
        value : t,
      })),
      onFilter : (filterValue, record) : boolean => record.status.includes(filterValue as string),
    },
    {
      title     : 'Action',
      dataIndex : 'action',
      align     : 'center',
      fixed     : 'right',
      width     : 80,
      render    : (_, record) : React.ReactNode => (
        <Space align = {'center'} size = {4}>
          <Button
            type = {'default'}
            icon = {<EditFilled />}
            size = {'small'}
            onClick = {(e) : void => {
              e.stopPropagation();
              navigate(`/trades/${record.id.toString()}/edit`);
            }}
          />

          <Button type = {'default'} icon = {<DeleteFilled />} size = {'small'} />
        </Space>
      ),
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (!hiddenInfos) {
      setTradesList(
        tradesListData.map((trade, i) => {
          const t = {...trade};
          t.clientName = tradesListData[i].clientName;
          return t;
        })
      );

      // Display commissions colunmns
      setTableColumns(columns);
      return;
    }

    setTradesList(
      tradesListData.map((trade, i) => {
        const t = {...trade};
        t.clientName = tradesListData[i].clientId;
        return t;
      })
    );

    // Hide some columns for non authorized users
    setTableColumns(
      columns.filter((c) => !['Aydo Commission', 'Total Commission', 'Action'].includes(c.title as string))
    );
  }, [hiddenInfos]);

  const exportExcel = () : void => {
    const workbook = XLSX.utils.book_new();

    // Convert data to a worksheet
    let dataToExport = [...tradesList];
    if (hiddenInfos) {
      dataToExport = tradesList.map((trade) => {
        const t = {...trade};
        delete (t as Partial<TradeDataType>).clientName;
        return t;
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'trade-list.xlsx');
  };

  const hideOrShowColumns = (checked : boolean, columnTitle : string) : void => {
    if (checked) {
      setTableColumns([...tableColumns, columns.find((col) => col.title === columnTitle)!]);
    } else {
      setTableColumns(tableColumns.filter((c) => !columnTitle.includes(c.title as string)));
    }
  };

  const expandedRowRender = (custodians : TradeDetailsType[], currency : CurrencyNameType) : React.ReactNode => {
    const innerColumns : TableColumnsType<TradeDetailsType> = [
      {
        title     : 'Custodian Name',
        dataIndex : 'name',
        key       : 'name',
      },
      {
        title     : 'Amount',
        dataIndex : 'amount',
        key       : 'amount',
        render    : (val) : string => parseCurrency(val as number, currency),
      },
    ];

    return (
      custodians.length === 0
        ? (
          <Flex justify = {'center'}>
            <p>No custodians</p>
          </Flex>
        )
        : (
          <Table
            columns = {innerColumns}
            rowKey = {(record) : string => record.id.toString()}
            id = {'expanded__table__inner__table--1'}
            className = {'expanded__table'}
            dataSource = {custodians}
            pagination = {false}
            rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          />
        )
    );
  };

  return (
    <>
      <div className = {'trade_list_container'}>
        <CustomTable
          dataList = {tradesList}
          columns = {tableColumns}
          rowKey = {(record) : string => record.id.toString()}
          colsTopApplyDateFilter = {['issueDate', 'transactionDate']}
          colsTopApplySeachFilter = {['isin', 'productName', 'clientName']}
          pagination = {tradesList.length <= ITEMS_PER_PAGE
            ? false
            : {
              position        : ['bottomCenter'],
              defaultPageSize : ITEMS_PER_PAGE,
            }}
          expandable = {{
            expandedRowRender : (record) : React.ReactNode => expandedRowRender(record.custodians, record.currency),
            expandIcon        : ({
              expanded, onExpand, record,
            }) : React.ReactNode => (
              <Button
                type = {'text'}
                icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                onClick = {(e) : void => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ),
          }}
          actions = {[
            {
              title   : 'Show/Hide Columns',
              onClick : () : void => setIsModalOpen(true),
              variant : 'default',
            },
            {
              title   : 'Export',
              onClick : () : void => exportExcel(),
              variant : 'primary',
            },
            {
              title   : 'Add Trade',
              onClick : () : void => navigate('/trades/new'),
              variant : 'primary',
            },
          ]}
          filters = {[
            {
              title    : 'Filter by client',
              selected : selectedClient,
              values   : clientsList,
              onClick  : (val) : void => {
                handleClientFilter(val);
              },
              showSearch : true,
            },
          ]}
          CustomComponent = {(
            <Space>
              <label>Hide:</label>

              <Switch onChange = {(checked : boolean) : void => setHiddenInfos(checked)} />
            </Space>
          )}
          onRow = {(record) => ({onClick : () : void => navigate(`/trades/${record.id.toString()}`)})}
        />
      </div>

      <Modal
        width = {600}
        title = {'Show/Hide Columns'}
        open = {isModalOpen}
        footer = {[
          <Button key = {'reset'} type = {'primary'} onClick = {() : void => setTableColumns(columns)}>
            Reset
          </Button>,
          <Button key = {'back'} type = {'link'} onClick = {() : void => setIsModalOpen(false)}>
            Close
          </Button>,
        ]}
        onCancel = {() : void => setIsModalOpen(false)}
      >
        <Row className = {'trade__list__columns'} gutter = {[16, 16]}>
          {columns.map((c, i) => (
            <Col key = {i} span = {8}>
              <Checkbox
                checked = {Boolean(tableColumns.find((col) => col.title === c.title))}
                onChange = {(e) : void => hideOrShowColumns(e.target.checked, c.title as string)}
              >
                {c.title as string}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export {TradeList};
