/* eslint-disable max-depth */
import React, {useEffect, useState} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {ReadyState, useEventSource} from 'react-use-websocket';

import {
  CaretDownOutlined, CaretUpOutlined
} from '@ant-design/icons';
import {Spin, Table} from 'antd';
import type {TableProps} from 'antd';

import capitalize from 'lodash/capitalize';

import {CONFIG_BACKEND_SERVER_URL} from '../../../config/config';
import {defaultIssuerSort, issuersList} from '../../../data/Issuers';
import {QuoteStatusEnum} from '../../../data/enums/pricing';
import {parsePercentStringToFloat} from '../../../utils/stringUtils';

import type {Issuer} from '../../../data/Issuers';
import type {GetPriceResultBody} from '../../../data/ReturnBody';


import './../__styles__/styles.scss';


type PricingResultProps = {
  issuersCalled : string[],
  solveFor      : string,
  frequency     : number,
};

export const PricingResult = () : React.JSX.Element => {
  let priceResultBody : GetPriceResultBody;
  let issuer : Issuer | undefined;

  const {priceId} = useParams();
  const context : PricingResultProps = useOutletContext();
  const {
    lastEvent, readyState,
  } = useEventSource(`${CONFIG_BACKEND_SERVER_URL}/price/${priceId}/${context.solveFor}`);
  const [sortedIssuerList, setSortedIssuerList] = useState<Issuer[]>(
    context.issuersCalled.map((i) : Issuer => {
      issuer = issuersList.find((e) : boolean => e.name === i) as Issuer;
      return {
        key          : issuer.key,
        logo         : issuer.logo,
        name         : issuer.name,
        quoteStatus  : QuoteStatusEnum.quoting,
        quoteId      : null,
        displayPrice : null,
        price        : null,
        error        : null,
        disabled     : false,
      };
    })
  );

  useEffect(() => {
    setSortedIssuerList(sortedIssuerList.sort(defaultIssuerSort));
  }, []);

  let numberOfCouponPerYear = 0;
  let frequencySymbol = '';

  switch (context.frequency) {
    case 1:
      numberOfCouponPerYear = 12;
      frequencySymbol = 'm';
      break;
    case 3:
      numberOfCouponPerYear = 4;
      frequencySymbol = 'q';
      break;

    case 6:
      numberOfCouponPerYear = 2;
      frequencySymbol = 's';
      break;

    case 12:
      numberOfCouponPerYear = 1;
      frequencySymbol = 'a';
      break;

    default:
      console.error(`Bad frequency : ${context.frequency}`);
  }

  useEffect(() : void => {
    const issuers = [...sortedIssuerList];

    if (readyState === ReadyState.CLOSED) {
      issuers.forEach((e) => {
        if (e.quoteStatus === QuoteStatusEnum.quoting) {
          e.quoteStatus = QuoteStatusEnum.failed;
        }
      });
    }

    if (lastEvent) {
      priceResultBody = JSON.parse(lastEvent.data as string) as GetPriceResultBody;
      issuer = issuers.find((e) : boolean => e.name === priceResultBody.issuer);

      if (!issuer) {
        return;
      }

      if (priceResultBody.error) {
        issuer.quoteStatus = QuoteStatusEnum.failed;
        issuer.error = priceResultBody.error;
      }
      if (priceResultBody.data) {
        const parsedData = parsePercentStringToFloat(priceResultBody.data);
        if (isNaN(parsedData)) {
          issuer.quoteStatus = QuoteStatusEnum.failed;
        } else {
          issuer.quoteStatus = QuoteStatusEnum.quoted;
          issuer.price = parsedData;
          if (context.solveFor === 'coupon') {
            issuer.displayPrice = `${(parsedData / numberOfCouponPerYear).toFixed(2)}% p.${frequencySymbol}.`;
            if (numberOfCouponPerYear !== 1) {
              issuer.displayPrice = `${issuer.displayPrice} (${parsedData.toFixed(2)}% p.a.)`;
            }
          } else {
            issuer.displayPrice = `${parsedData}%`;
          }
        }
      }

      issuer.quoteId = priceResultBody.quoteId;

      issuers.sort((l, r) : number => {
        if ((l.quoteStatus === QuoteStatusEnum.quoting && r.quoteStatus === QuoteStatusEnum.quoting)
          || (l.quoteStatus === QuoteStatusEnum.failed && r.quoteStatus === QuoteStatusEnum.failed)) {
          return 0;
        }
        if (l.quoteStatus === QuoteStatusEnum.quoted && r.quoteStatus === QuoteStatusEnum.quoted
          && l.price !== null && r.price !== null) {
          if (context.solveFor === 'reoffer') {
            return l.price - r.price;
          }
          return r.price - l.price;
        }
        if (l.quoteStatus === QuoteStatusEnum.failed) {
          return 1;
        }
        if (r.quoteStatus === QuoteStatusEnum.failed) {
          return -1;
        }
        if (l.quoteStatus === QuoteStatusEnum.quoting && r.quoteStatus === QuoteStatusEnum.quoted) {
          return 1;
        }
        if (r.quoteStatus === QuoteStatusEnum.quoting && l.quoteStatus === QuoteStatusEnum.quoted) {
          return -1;
        }
        return 0;
      });
      setSortedIssuerList(issuers);
    }
  }, [lastEvent, readyState]);

  const issuersColumn : TableProps<Issuer>['columns'] = [
    {
      dataIndex : 'logo',
      key       : 'logo',
      render    : (path : string) : React.JSX.Element => <img className = {'logoIssuers'} src = {path} alt = {'Issuer Logo'} />,
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>{capitalize(context.solveFor)}</span>,
      dataIndex : 'price',
      key       : 'price',
      render    : (_, row) : React.JSX.Element => {
        if (!row.quoteStatus) {
          return <span>Unexpected status</span>;
        }
        switch (row.quoteStatus) {
          case QuoteStatusEnum.quoting:
            return <Spin spinning />;
          case QuoteStatusEnum.quoted:
            return <span className = {'tableLabel'}>{row.displayPrice}</span>;
          case QuoteStatusEnum.failed:
            return <span className = {'tableLabel'}>Unable to price</span>;
          default:
            return <span>Unexpected status</span>;
        }
      },
      align : 'center',
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>Quote Status</span>,
      dataIndex : 'quoteStatus',
      key       : 'quoteStatus',
      render    : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
      align     : 'center',
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>Quote Id</span>,
      dataIndex : 'quoteId',
      key       : 'quoteId',
      render    : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
      align     : 'center',
    },
  ];

  return (
    <div className = {'cardWrapperResult dynamicOrangeWrapper'}>
      <div className = {'cardResult'}>
        <div className = {'dynamicOrange cardHeader'}>
          <h2>Issuers</h2>
        </div>

        <div className = {'cardContentResult'}>
          <Table
            columns = {issuersColumn}
            dataSource = {sortedIssuerList}
            pagination = {false}
            size = {'large'}
            rowClassName = {'rowClassname'}
            scroll = {{
              scrollToFirstRowOnChange : false,
              y                        : '50vh',
            }}
            expandable = {{
              expandedRowRender : (record) : React.JSX.Element => <p style = {{margin : 0}}>{record.error}</p>,
              rowExpandable     : (record) : boolean => Boolean(record.error),
              expandIcon        : ({
                expanded, onExpand, record, expandable,
              }) : React.JSX.Element => {
                if (expandable) {
                  if (expanded) {
                    return <CaretUpOutlined onClick = {(e) : void => onExpand(record, e)} />;
                  }
                  return <CaretDownOutlined onClick = {(e) : void => onExpand(record, e)} />;
                }
                return <></>;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
