import { Button, Flex, Form, Input, Modal, Space } from 'antd'
import React from 'react'

const CreateIsinModal: React.FC<{
  isModalOpen: boolean
  close: () => void
}> = ({ isModalOpen, close }) => {
  const [form] = Form.useForm()

  const handleSubmit = () => {
    // TODO: create isin
  }
  return (
    <Modal
      title="Edit ISIN of the Current Product"
      width={400}
      open={isModalOpen}
      onCancel={close}
      footer={[]}
    >
      <Form
        name="createIsin"
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item label="ISIN" name="isin" rules={[{ required: true }]}>
          <Input placeholder="ISIN" />
        </Form.Item>
        <Flex justify='end'>
          <Space>
            <Button key="cancel" onClick={close}>
              Cancel
            </Button>
            <Form.Item style={{ marginBottom: 0 }} key="submit">
              <Button type="primary" htmlType="submit">
                OK
              </Button>
            </Form.Item>
          </Space>
        </Flex>
      </Form>
    </Modal>
  )
}

export { CreateIsinModal }
