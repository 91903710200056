import React, {useEffect, useState} from 'react';

import {DeleteOutlined} from '@ant-design/icons';
import {
  Button, Flex, Input,
  Select,
  Space
} from 'antd';

import {myFetch} from '../../../../config/api';
import {mockPlaces, mockTickers} from '../../ProductPage/data';

import './AddUnderlyingsForm.scss';


type CompanyItemType = {
  companyId       : number,
  companyName     : string,
  isin            : string,
  tickerWithPlace : string,
};

export type EquityLikeFormType = {
  ticker      : string,
  place       : string,
  isin        : string,
  newIsin     : boolean,
  companyName : string,
  companyId   : number,
};

type AddUnderlyingsFormType = {
  onAddUnderlying : (equityLikeList : EquityLikeFormType[], underlyingRates : string[])=> void,
};


const emptyItem = {
  companyId   : 0,
  isin        : '',
  ticker      : '',
  place       : '',
  newIsin     : false,
  companyName : '',
};


const AddUnderlyingsForm : React.FC<AddUnderlyingsFormType> = ({onAddUnderlying}) : React.ReactNode => {
  const [equityLikeList, setEquityLikeList] = useState<EquityLikeFormType[]>([emptyItem]);
  const [companies, setCompanies] = useState<CompanyItemType[]>([]);
  const [rates, setRates] = useState<string[]>([]);
  const [fetchingInfos, setFetchingInfos] = useState(false);

  useEffect(() : void => {
    const validEquityLike = equityLikeList.filter((item) => item.isin && item.companyId && item.ticker && item.place);
    const validRates = rates.filter((rate) => rate.length);
    onAddUnderlying(validEquityLike, validRates);
  }, [rates, equityLikeList]);


  const addNewEquityLike = () : void => {
    setEquityLikeList([...equityLikeList, emptyItem]);
  };

  const onChangeEquityLikeForm = (index : number, paramKey : keyof EquityLikeFormType, paramValue : string) : void => {

    const currentItem : EquityLikeFormType = {
      ...equityLikeList[index],
      [paramKey] : paramValue,
    };

    const newList = equityLikeList.map((item, i) => (i === index ? currentItem : item));

    setEquityLikeList(newList);

    // Try getting isin and company name from ticker and place
    if (currentItem.ticker && currentItem.place && !currentItem.isin) {
      setFetchingInfos(true);

      myFetch('GET', `/underlyings/${currentItem.ticker}.${currentItem.place}`)
        .then((response) : void => {
          const fetchedInfos = response as CompanyItemType;
          const {
            isin, companyName, companyId,
          } = fetchedInfos;

          setEquityLikeList(newList.map((item, i) => (i === index
            ? {
              ...item,
              companyId,
              isin,
              newIsin : false,
              companyName,
            }
            : item)));
        })
        .catch(() : void => {
          setEquityLikeList(newList.map((item, i) => (i === index
            ? {
              ...item,
              isin        : '',
              newIsin     : true,
              companyName : '',
              companyId   : 0,
            }
            : item)));
        })
        .finally(() => setFetchingInfos(false));
    }

    // Try getting ticker with place and company info from isin
    if (currentItem.isin && (!currentItem.place || !currentItem.ticker)) {
      myFetch('GET', `/underlyings/isin/${currentItem.isin}`)
        .then((response) : void => {
          const fetchedInfos = response as CompanyItemType;
          const {
            companyName, companyId, tickerWithPlace,
          } = fetchedInfos;

          setEquityLikeList(newList.map((item, i) => (i === index
            ? {
              ...item,
              companyId,
              ticker  : tickerWithPlace.split('.')[0],
              place   : tickerWithPlace.split('.')[1],
              newIsin : false,
              companyName,
            }
            : item)));
        })
        .catch(() : void => {
          setEquityLikeList(newList.map((item, i) => (i === index
            ? {
              ...item,
              newIsin     : true,
              companyName : '',
              companyId   : 0,
            }
            : item)));
        })
        .finally(() => setFetchingInfos(false));
    }
  };

  const getCompanies = () : void => {
    myFetch('GET', '/companies')
      .then((response) : void => {
        const fetchedCompanies = response as CompanyItemType[];
        setCompanies(fetchedCompanies);
      })
      .catch((e : unknown) : void => console.error(e));
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div className = {'add-underlyings-form'}>
      <p style = {{marginBottom : '6px'}}>Underlyings</p>

      <Space>
        <Button
          disabled = {fetchingInfos}
          type = {'primary'}
          className = {'add-underlying-button'}
          onClick = {addNewEquityLike}
        >Add Equity Like
        </Button>

        <Button
          disabled = {fetchingInfos}
          type = {'primary'}
          className = {'add-underlying-button'}
          onClick = {() : void => setRates([...rates, ''])}
        >Add Rate
        </Button>

        <Button disabled type = {'primary'} className = {'add-underlying-button'}>Add CDS</Button>
      </Space>

      <div className = {'equity-like-form'}>

        {equityLikeList.map((item, index) => (
          <Flex vertical = {true} key = {index}>
            {item.companyName && <p>{item.companyName}</p>}

            <Space.Compact
              style = {{
                width        : '100%',
                marginBottom : '6px',
              }}
            >


              <Select
                showSearch
                disabled = {fetchingInfos}
                value = {item.ticker.length ? item.ticker : null}
                placeholder = {'Select Ticker'}
                className = {'product-select-input'}
                style = {{width : '30%'}}
                options = {mockTickers
                  .map((ticker) => ({
                    label : ticker,
                    value : ticker,
                  }))}
                onChange = {(ticker) : void => onChangeEquityLikeForm(index, 'ticker', ticker)}
              />

              <Select
                showSearch
                disabled = {fetchingInfos}
                value = {item.place.length ? item.place : null}
                placeholder = {'Select Place'}
                className = {'product-select-input'}
                style = {{width : '25%'}}
                options = {mockPlaces.map((place) => ({
                  label : place,
                  value : place,
                }))}
                onChange = {(place) : void => onChangeEquityLikeForm(index, 'place', place)}
              />

              <Input
                className = {'product-form-input'}
                disabled = {fetchingInfos}
                value = {item.isin}
                placeholder = {'ISIN'}
                style = {{width : '40%'}}
                onChange = {(e) : void => onChangeEquityLikeForm(index, 'isin', e.target.value)}
              />

              <Button
                type = {'text'}
                icon = {<DeleteOutlined />}
                onClick = {() : void => setEquityLikeList(equityLikeList.filter((_, i) => i !== index))}
              />
            </Space.Compact>
          </Flex>
        ))}

        {rates.map((item, index) => (
          <Flex
            key = {index}
            justify = {'space-between'}
            style = {{marginBottom : '6px'}}
          >
            <Space.Compact style = {{width : '100%'}}>
              <Input
                className = {'product-form-input'}
                value = {item}
                placeholder = {'Enter Underlying Rate'}
                style = {{width : '100%'}}
                onChange = {(e) : void => setRates(rates.map((r, i) => (i === index ? e.target.value : r)))}
              />

              <Button
                type = {'text'}
                icon = {<DeleteOutlined />}
                onClick = {() : void => setRates(rates.filter((_, i) => i !== index))}
              />
            </Space.Compact>
          </Flex>
        ))}
      </div>


    </div>
  );
};

export {AddUnderlyingsForm};
