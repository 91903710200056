import Keycloak from './../../node_modules/keycloak-js/lib/keycloak';
import {CONFIG_KEYCLOAK_CLIENT_ID, CONFIG_KEYCLOAK_REALM, CONFIG_KEYCLOAK_URL} from './config';

export const keycloakInstance = new Keycloak({
  url      : CONFIG_KEYCLOAK_URL,
  realm    : CONFIG_KEYCLOAK_REALM,
  clientId : CONFIG_KEYCLOAK_CLIENT_ID,
});

try {
  const authenticated = await keycloakInstance.init({onLoad : 'login-required'});
  if (authenticated) {
    console.warn('User is authenticated');
  } else {
    console.warn('User is not authenticated');
  }
} catch (error) {
  console.error('Failed to initialize adapter:', error);
}

