import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {
  CaretDownOutlined, CaretUpOutlined, EyeOutlined, InfoCircleOutlined, LoadingOutlined
} from '@ant-design/icons';
import {
  Button, Col, Empty, Flex, Row, Spin, Table, Tag,
  Tooltip
} from 'antd';
import type {TableColumnsType} from 'antd';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {CalendarEvents} from '../../../Shared/CalendarEvents/CalendarEvents';
import {CustomTable} from '../../../Shared/CustomTable/CustomTable';
import {MainTitle} from '../../../Shared/MainTitle/MainTitle';
import billIcon from '../../../assets/billIcon.svg';
import userIcon from '../../../assets/user.png';
import {myFetch} from '../../../config/api';
import {
  expertGreen, goldenYellow, whiteColor
} from '../../../styles/colors';
import {parseCurrency} from '../../../utils/currencyparser';
import {isNumberString} from '../../../utils/stringUtils';
import {ProductValorisation} from '../Shared/ProductValuation/ProductValuation';
import {productsListData} from '../mockData/mockData';


import type {ProductEvent, SingleProductType} from '../../../data/ProductType';
import type {TradeDataType, TradeDetailsType} from '../../../data/TradesTypes';
import type {CurrencyNameType} from '../../../data/currency';


import './ProductPage.scss';


dayjs.extend(isSameOrBefore);

const ProductPage = () : React.ReactNode => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<SingleProductType>();
  const [isLoading, setIsLoading] = useState(false);
  const {productId} = useParams<{productId : string}>();
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);

  const getProduct = () : void => {
    if (!productId) {
      return;
    }
    setIsLoading(true);

    myFetch('GET', `/products/${productId.toString()}`)
      .then((response) : void => {
        const fetchedProduct = response as SingleProductType;
        if (!fetchedProduct.events) {
          fetchedProduct.events = [];
        }
        if (!fetchedProduct.fields) {
          fetchedProduct.fields = [];
        }
        setProduct(fetchedProduct);
        setCalendarEvents(fetchedProduct.events);
      })
      .catch((e : unknown) : void => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // getProduct(); TODO: comment out after the Demo
  }, []);

  useEffect(() => {
    // TODO: To be removed after the Demo
    const jsonData : unknown = productsListData.find((p) => p.id === Number(productId));
    const productData = jsonData as SingleProductType;
    setProduct(productData);
    const events = productData.events as ProductEvent[];
    setCalendarEvents(events.map((e) => ({
      ...e,
      outcomeValue : productData.coupon.toString(),
    })));
  }, []);

  useEffect(() => {
    if (!product) {
      return;
    }

    // Add issue and maturity events and initial valuation date
    setCalendarEvents([
      ...calendarEvents,
      ...[
        {
          id            : Date.now(),
          status        : dayjs(product.maturityDate).isAfter(dayjs()) ? 'Future' : 'Past',
          eventType     : 'Maturity',
          paymentDate   : dayjs(product.maturityDate).format('YYYY-MM-DD'),
          valuationDate : dayjs(product.maturityDate).format('YYYY-MM-DD'),
        },
        {
          id            : Date.now(),
          status        : dayjs(product.issueDate).isAfter(dayjs()) ? 'Future' : 'Past',
          eventType     : 'Issue',
          paymentDate   : dayjs(product.issueDate).format('YYYY-MM-DD'),
          valuationDate : dayjs(product.issueDate).format('YYYY-MM-DD'),
        },
        {
          id            : Date.now(),
          status        : dayjs(product.initialValuationDate).isAfter(dayjs()) ? 'Future' : 'Past',
          eventType     : 'Initial Fixing',
          paymentDate   : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
          valuationDate : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
        },

        // Add today event marker
        {
          id            : Date.now(),
          status        : 'Future',
          eventType     : 'Today',
          paymentDate   : dayjs().format('YYYY-MM-DD'),
          valuationDate : dayjs().format('YYYY-MM-DD'),
        },
      ],
    ]);
  }, [product]);

  const expandedRowRender = (custodians : TradeDetailsType[], currency : CurrencyNameType) : React.ReactNode => {
    const innerColumns : TableColumnsType<TradeDetailsType> = [
      {
        title     : 'Custodian Name',
        dataIndex : 'name',
        key       : 'name',
      },
      {
        title     : 'Amount',
        dataIndex : 'amount',
        key       : 'amount',
        render    : (val) : string => parseCurrency(val as number, currency),
      },
    ];

    return (
      custodians.length === 0
        ? (
          <Flex justify = {'center'}>
            <p>No custodians</p>
          </Flex>
        )
        : (
          <Table
            columns = {innerColumns}
            className = {'expanded__table'}
            id = {'expanded__table__inner__table--2'}
            dataSource = {custodians}
            pagination = {false}
            rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          />
        )
    );
  };

  if (isLoading) {
    return (
      <Flex
        align = {'center'}
        justify = {'center'}
        gap = {'middle'}
        style = {{height : '80vh'}}
      >
        <Spin indicator = {(
          <LoadingOutlined
            spin
            style = {{
              fontSize : 48,
              color    : whiteColor,
            }}
          />
        )}
        />
      </Flex>
    );
  }

  if (!product) {
    return <Empty description = {'Product not found'} image = {Empty.PRESENTED_IMAGE_SIMPLE} />;
  }


  const columns : TableColumnsType<TradeDataType> = [
    {
      title     : 'Client',
      dataIndex : 'clientName',
      width     : '33%',
    },
    {
      title     : 'Seller',
      dataIndex : 'sellerName',
      width     : '33%',
    },
    {
      title     : 'Outstanding Amount',
      dataIndex : 'nominal',
      render    : (_, record) : React.ReactNode => <>{parseCurrency(record.custodians.reduce((acc, obj) => acc + obj.amount, 0), record.currency)}</>,
      align     : 'right',
    },
    {
      title     : '',
      dataIndex : '',
      key       : '',
      render    : (_, record) : React.ReactNode => (
        <Button href = {`/trades/${record.id.toString()}`} type = {'text'} onClick = {(e) : void => e.stopPropagation()}>
          <EyeOutlined className = {'view-trade-icon'} />
        </Button>
      ),
    },
  ];

  // TODO: will be improved after demo
  let frequency = 1;
  if (product.events && product.events.length > 0) {
    frequency = dayjs(product.events[0].valuationDate).diff(new Date(product.initialValuationDate).valueOf()) / 1000 / 3600 / 24 / 30 / 12;
  }

  const {coupon} = product;
  let couponValue = coupon.toString();
  let couponPerAnnumValue = coupon.toString();
  if (isNumberString(couponValue)) {
    couponPerAnnumValue = `${(parseFloat(couponValue) * 100).toFixed(2)}%`;
    couponValue = `${(parseFloat(couponValue) * 100 * frequency).toFixed(2)}%`;
  }

  return (
    <div className = {'product__container'}>
      <Row
        gutter = {[16, 16]}
        align = {'middle'}
        className = {'product__header'}
        justify = {'space-between'}
      >
        <Col lg = {20} md = {12} xs = {24}>
          <MainTitle showReturnButton text = {product.name} />
        </Col>

        <Col lg = {4} md = {12} xs = {24}>
          <Flex justify = {'flex-end'}>
            <ProductValorisation
              valorisationValue = {product.valorisation}
              prefixText = {'Valorisation: '}
              style = {{
                padding      : '12px 18px',
                fontWeight   : 500,
                border       : 'none',
                marginBottom : '12px',
              }}
            />
          </Flex>

          <Flex justify = {'end'} align = {'center'}>
            <span className = {'product__status_text'}>Status: </span>

            <Tag className = {'product__status'} color = {product.status === 'Active' ? 'green' : 'red'}>
              {product.status}
            </Tag>

            <Button
              type = {'primary'}
              size = {'middle'}
              style = {{
                marginLeft : '16px',
                background : whiteColor,
                color      : expertGreen,
              }}
              onClick = {() : void => navigate(`/products/${product.id.toString()}/edit`)}
            >Edit
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row gutter = {[16, 16]}>
        <Col lg = {6} md = {12} xs = {24}>
          <div className = {'content__box content__box__left'}>
            <Flex className = {'outstanding-amount-box'}>
              <img src = {billIcon} />

              <p>{parseCurrency(product.trades.reduce((acc, obj) => acc + obj.nominal, 0), product.currency)}</p>

              <span>Outstanding Amount</span>
            </Flex>

            <div>


              <h4>General</h4>

              <div className = {'content__box__left_section'}>
                <Flex wrap = {false} className = {'info__item info__item__odd'} align = {'center'}>
                  <label>
                    ISIN
                    <Tooltip
                      color = {expertGreen}
                      overlayInnerStyle = {{color : whiteColor}}
                      title = {'International Securities Identification Number'}
                    >
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{product.isin}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__even'}>
                  <label>
                    Issuer
                    <Tooltip
                      color = {expertGreen}
                      overlayInnerStyle = {{color : whiteColor}}
                      title = {'Company that issues an garanteed the product'}
                    >
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{product.issuerShortName}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Product Type
                  </label>

                  <p>{product.productType}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity
                  </label>

                  <p>
                    {/* // TODO: will be removed */}
                    {(dayjs(product.maturityDate).diff(new Date(product.initialValuationDate).valueOf()) / 1000 / 3600 / 24 / 30 / 12).toFixed(0)} Year(s)
                  </p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Currency
                  </label>

                  <p>{product.currency}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label style={{ flexBasis : '150px'}}>
                    Coupon
                    <Tooltip color = {expertGreen} overlayInnerStyle = {{color : whiteColor}} title = {'Value Per Annum'}>
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{couponPerAnnumValue} p.a</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Initial Fixing Date
                  </label>

                  <p>{dayjs(product.initialValuationDate).format('DD/MM/YYYY')}</p>
                </Flex>


                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity Date
                  </label>

                  <p>{dayjs(product.maturityDate).format('DD/MM/YYYY')}</p>
                </Flex>
              </div>


              {product.fields && (
                <>
                  <h4 className = {'section__title_margin'}>Others</h4>

                  <div className = {'content__box__left_section'}>
                    {product.fields.map((field, i) => (
                      <Flex key = {i} wrap = {false} className = {`info__item ${i % 2 ? 'info__item__odd' : 'info__item__even'}`}>
                        <label>{field.customKey}</label>

                        <p>{field.customValue}</p>
                      </Flex>
                    ))}
                  </div>
                </>
              )}

              <div className = {'action_section'}>
                <Flex align = {'center'}>
                  <img className = {'userIcon'} src = {userIcon} />

                  <p className = {'desc'}>Ready to invest? Need more information?</p>
                </Flex>

                <Button className = {'connect_button'} type = {'primary'}>Contact us</Button>
              </div>
            </div>

          </div>
        </Col>

        <Col
          className = {'grid__column'}
          lg = {12}
          md = {12}
          xs = {24}
        >
          <div className = {'content__box__nopadding'} style = {{marginBottom : '16px'}}>
            <CustomTable<TradeDataType>
              columns = {columns}
              dataList = {product.trades}
              rowKey = {(record) : string => record.id.toString()}
              pagination = {false}
              bordered = {false}
              headerHidden = {true}
              expandable = {{
                expandedRowRender      : (record) : React.ReactNode => expandedRowRender(record.custodians, record.currency),
                defaultExpandedRowKeys : ['0'],
                expandRowByClick       : true,
                expandIcon             : ({
                  expanded, onExpand, record,
                }) : React.ReactNode => (expanded
                  ? <Button type = {'text'} icon = {<CaretUpOutlined />} onClick = {(e) : void => onExpand(record, e)} />
                  : <Button type = {'text'} icon = {<CaretDownOutlined />} onClick = {(e) : void => onExpand(record, e)} />),
              }}
            />
          </div>

          <div className = {'content__box content__box__nopadding'}>
            <div className = {'pdf__viewer'}>
              <h4 className = {'box__section__title'}>Termsheet</h4>

              <iframe src = {product.termSheet} width = {'100%'} height = {'800px'} />
            </div>
          </div>
        </Col>

        <Col lg = {6} md = {12} xs = {24}>
          <div className = {'content__box content__box__calendar'}>
            <h4 className = {'box__section__title'}>Calendar</h4>

            <CalendarEvents events = {calendarEvents.map((event) => ({
              ...event,
              outcomeValue: couponValue,
            }))}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export {ProductPage};
