import {HeaderTabsEnum} from './enums/header';

export type Route = {
  tab  : HeaderTabsEnum | null,
  path : string,
};

export const HomeRoute : Route = {
  tab  : null,
  path : '/',
};

export const PricingRoute : Route = {
  tab  : HeaderTabsEnum.ayDeal,
  path : '/pricing',
};

export const EndingProductsRoute : Route = {
  tab  : undefined,
  path : '/products/end-of-life',
};

export const ProductEventManagerRoute : Route = {
  tab  : HeaderTabsEnum.eventsManager,
  path : '/products/events/manager',
};

export const ProductsRoute : Route = {
  tab  : HeaderTabsEnum.products,
  path : '/products',
};

export const TradesListRoute : Route = {
  tab  : HeaderTabsEnum.tradesList,
  path : '/trades',
};

export const CreateTradePageRoute : Route = {
  tab  : null,
  path : '/trades/new',
};

export const EditTradePageRoute : Route = {
  tab  : null,
  path : '/trades/:tradeId/edit',
};

export const TradePageRoute : Route = {
  tab  : null,
  path : '/trades/:tradeId',
};

export const ProductPageRoute : Route = {
  tab  : null,
  path : '/products/:productId',
};

export const CreateProductPageRoute : Route = {
  tab  : undefined,
  path : '/products/new',
};

export const EditProductPageRoute : Route = {
  tab  : undefined,
  path : '/products/:productId/edit',
};

export const UnwindPageRoute : Route = {
  tab  : null,
  path : '/unwind',
};

export const ExplorerRoute : Route = {
  tab  : HeaderTabsEnum.explorer,
  path : 'https://www.aydofinance.com/shape',
};
