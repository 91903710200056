export enum TradeStatusClass {
  Booked = 'booked',
  'Order Received' = 'order__received',
  'Invoice Sent' = 'invoice__sent',
  'Commission to be paid' = 'commission__tobePaid',
  Closed = 'closed',
}

export enum EventTypesEnum {
  Coupon = 'Coupon',
  Autocall = 'Autocall',
  InitialFixing = 'Initial Fixing',
  IssueDate = 'Issue Date',
  FinalFixing = 'Final Fixing',
  MaturityDate = 'Maturity',
}

export enum CustomProductAttrEnum {
  Custom = 'Custom',
  ToSet = 'To Set',
}

export enum ProductListPageViewEnum {
  DEFAULT = 'Default',
  BY_CLIENT = 'Client',
  BY_SELLER = 'Relationship manager',
  BY_PRODUCT_TYPE = 'Product Type',
  BY_VALORIZATION = 'Group By Valuation',
  BY_ISSUER = 'Issuer',
  BY_NEXT_EVENT = 'Next Event',
}

export enum EventStatuslEnum {
  Past = 'Past',
  NextEvent = 'Next event',
  Future = 'Future',
  Cancel = 'Cancel',
}
