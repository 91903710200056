import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {DownloadOutlined, HistoryOutlined} from '@ant-design/icons';
import {
  Button, Checkbox, Col, Divider, Empty, Flex, Row, Timeline, Upload
} from 'antd';


import dayjs from 'dayjs';


import {MainTitle} from '../../../Shared/MainTitle/MainTitle';
import {parseCurrency} from '../../../utils/currencyparser';
import {tradesListData} from '../mockData/mockData';

import {tradeStatuses} from './data';

import type {TradeInfo} from '../../../data/TradesTypes';

import './TradePage.scss';

const TradePage = () : React.ReactNode => {
  const {Dragger} = Upload;
  const navigate = useNavigate();
  const [trade, setTrade] = useState<TradeInfo>();
  const [statuses, setTradeStatuses] = useState(tradeStatuses);
  const {tradeId} = useParams<{tradeId : string}>();
  const [invoicesChecklist, setInvoicesChecklist] = useState([
    {
      name    : 'Client Invoice Sent',
      checked : false,
    },
    {
      name    : 'Invoice Received',
      checked : false,
    },
    {
      name    : 'Commission paid',
      checked : false,
    },
  ]);

  const handleInvoiceStatus = (index : number, checked : boolean) : void => {
    setInvoicesChecklist((prev) => prev.map((c, i) => {
      // Only allow the current item to be checked if the previous one is checked (or it's the first item, or is unchecking)
      if (i === index && (index === 0 || prev[index - 1].checked || !checked)) {
        return {
          ...c,
          checked,
        };
      }
      return c;
    }));
  };

  const onChangeStatus = (status : string) : void => {
    setTradeStatuses(statuses.map((s) => ({
      ...s,
      current : s.status === status,
    })));
  };

  useEffect(() => {
    if (!tradeId) {
      return;
    }

    // TODO: remove this after the demo
    const currentTrade = tradesListData.find((t) => t.id === parseInt(tradeId)) as TradeInfo;
    setTrade(currentTrade);
    onChangeStatus(currentTrade.status);
  }, []);

  if (!trade) {
    return <Empty description = {'Trade not found'} image = {Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const {currency} = trade;

  return (
    <div className = {'trade__container'}>
      <Flex className = {'trade__header'} align = {'center'} justify = {'space-between'}>
        <MainTitle showReturnButton text = {'Trade'} />

        <Button type = {'default'} onClick = {() : void => navigate(`/trades/${trade.id}/edit`)}>Edit</Button>
      </Flex>

      <Divider style = {{margin : '16px 0'}} />

      <Row gutter = {[16, 16]}>
        <Col md = {12} lg = {4} xs = {24}>
          <div className = {'content__box'}>
            <Timeline
              className = {'timeline'}
              items = {statuses.map((s) => ({
                color    : s.current ? 'green' : '',
                children : (
                  <a
                    key = {s.status}
                    className = {`timeline__btn ${s.current && 'timeline__btn__current'}`}
                    onClick = {() : void => onChangeStatus(s.status)}
                  >
                    {s.status}
                  </a>
                ),
              }))}
            />

            <h4 className = {'box__section__title_auto'}>Client Invoicing Checklist</h4>

            {invoicesChecklist.map((c, i) => (
              <Checkbox
                disabled = {i !== 0 && !invoicesChecklist[i - 1].checked}
                checked = {c.checked}
                key = {i}
                className = {'invoice__checkbox'}
                onChange = {(e) : void => handleInvoiceStatus(i, e.target.checked)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
        </Col>

        <Col md = {12} lg = {14} xs = {24}>
          <div className = {'content__box'}>
            <Row gutter = {32}>
              <Col md = {12} sm = {24}>
                <div className = {'content__box-left'}>
                  <h4>Main Features</h4>

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>ISIN Code:</label>

                    <p>{trade.isin}</p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'baseline'}>
                    <label>Product Name:</label>

                    <p>
                      <a href = {'#'}>{trade.productName}</a>
                    </p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Denomination:</label>

                    <p>1000</p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Issuer:</label>

                    <p>{trade.issuer}</p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Currency:</label>

                    <p>{currency}</p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Notional Amount:</label>

                    <p>{parseCurrency(trade.nominal, currency)}</p>
                  </Flex>

                  <h4 className = {'box__section__title'}>Date</h4>

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Trade Date:</label>

                    <p>{dayjs(trade.tradeDate).format('DD/MM/YYYY')}</p>
                  </Flex>

                  <Divider style = {{margin : '8px 0'}} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Issue Date:</label>

                    <p>{dayjs(trade.issueDate).format('DD/MM/YYYY')}</p>
                  </Flex>
                </div>
              </Col>

              <Col className = {'content__box-right'} md = {12} sm = {24}>
                <h4>AYDO {'<>'} Trading Entity</h4>

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Seller:</label>

                  <p>{trade.tradingEntity}</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Reoffer Price:</label>

                  <p>{(trade.nominal - trade.totalCommission) * 100 / trade.nominal}%</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Cash Amount:</label>

                  <p>{parseCurrency(trade.nominal - trade.totalCommission, currency)}</p>
                </Flex>

                <h4 className = {'box__section__title'}>AYDO {'<>'} Client</h4>

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Client Name:</label>

                  <p>{trade.clientName}</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Client Price:</label>

                  <p>100%</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Cash Amount:</label>

                  <p>{parseCurrency(trade.nominal, currency)}</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Commission:</label>

                  <p>{((trade.totalCommission - trade.aydoCommision) * 100 / trade.nominal).toFixed(2)}%</p>
                </Flex>

                <Divider style = {{margin : '8px 0'}} />

                <Flex wrap = {false} className = {'info__item info__item__dragger'} align = {'center'}>
                  <label>Signed Termsheet:</label>

                  <Dragger
                    className = {'dragger'}
                    name = {'file'}
                    accept = {'application/pdf'}
                    onChange = {(info) => {
                      // TODO
                    }}
                    onDrop = {(e) => {
                      // TODO
                      console.log('Dropped files', e.dataTransfer.files);
                    }}
                  >
                    <p className = {'ant-upload-text'}>Drag and drop a pdf file here, or click to select file</p>
                  </Dragger>
                </Flex>
              </Col>
            </Row>
          </div>
        </Col>

        <Col
          className = {'grid__column'}
          md = {24}
          lg = {6}
          xs = {24}
        >
          <div className = {'content__box'}>
            <Flex wrap = {false} className = {'info__item'} align = {'center'}>
              <label>Owner: </label>

              <span>{trade.sellerName}</span>
            </Flex>
          </div>

          <div className = {'content__box'}>
            <Flex vertical className = {'custodians__infos'}>
              <Flex justify = {'space-between'} align = {'center'}>
                <span>Custodians:</span>

                <Button type = {'text'} onClick = {() : void => navigate('/unwind')}>
                  <u>Unwind</u>
                </Button>
              </Flex>

              {trade.custodians.map((custodian) => (
                <div style = {{marginBottom : '8px'}} key = {custodian.id}>
                  <span className = {'custodians__infos__price'}>{parseCurrency(custodian.amount, currency)}</span>

                  <Flex align = {'center'}>
                    <Button type = {'text'} icon = {<DownloadOutlined />} size = {'middle'} />

                    <span className = {'custodians__infos__download'}>
                      {custodian.name} :

                      {parseCurrency(custodian.amount, currency)}
                    </span>
                  </Flex>

                </div>
              ))}

            </Flex>
          </div>

          <div className = {'content__box'}>
            <h4>Commission details</h4>

            <Flex wrap = {false} className = {'info__item'} align = {'center'}>
              <label>Total Commission:</label>

              <span>{parseCurrency(trade.totalCommission, currency)}</span>
            </Flex>

            <Divider style = {{margin : '8px 0'}} />

            <Flex wrap = {false} className = {'info__item'} align = {'center'}>
              <label>- Client Commission:</label>

              <span>{parseCurrency(trade.totalCommission - trade.aydoCommision, currency)}</span>
            </Flex>

            <Divider style = {{margin : '8px 0'}} />

            <Flex wrap = {false} className = {'info__item'} align = {'center'}>
              <label>= AYDO Commission:</label>

              <span>{parseCurrency(trade.aydoCommision, currency)}</span>
            </Flex>
          </div>

          <div className = {'content__box'}>
            <h4>Invoices</h4>

            <Flex vertical align = {'end'} className = {'invoices__download__buttons'}>
              <Button type = {'text'} icon = {<DownloadOutlined />} size = {'small'}>
                Invoice Trading Entity [En]
              </Button>

              <Button type = {'text'} icon = {<DownloadOutlined />} size = {'small'}>
                Invoice Trading Entity [Fr]
              </Button>

              <Button type = {'text'} icon = {<DownloadOutlined />} size = {'small'}>
                Invoice Client [En]
              </Button>

              <Button type = {'text'} icon = {<DownloadOutlined />} size = {'small'}>
                Invoice Client [Fr]
              </Button>
            </Flex>

            <Divider style = {{margin : '8px 0'}} />

            <Button
              className = {'view__trade__btn'}
              type = {'primary'}
              icon = {<HistoryOutlined />}
              size = {'small'}
            >
              View trade history
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export {TradePage};
