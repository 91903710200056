import React from 'react';

import {DeleteFilled, EditFilled} from '@ant-design/icons';
import {Button, Space} from 'antd';

import {EventTypesEnum} from '../../data/enums/lifecycle';

import type {ProductEvent} from '../../data/ProductType';
import { isNumberString } from '../../utils/stringUtils';

const labelToClassName : Map<string, string> = new Map([
  [EventTypesEnum.Coupon, '--coupon'],
  [EventTypesEnum.MaturityDate, '--maturity'],
  [EventTypesEnum.Autocall, '--autocall'],
  [EventTypesEnum.FinalFixing, '--finalfixing'],
  [EventTypesEnum.IssueDate, '--issue'],
  [EventTypesEnum.InitialFixing, '--initialfixing'],
]);

type CalendarEventCardProps = {

  event           : ProductEvent,
  className       : string | undefined,
  editionMode     : boolean | undefined,
  onChangeEvent ? : (eventIndex : number, action : 'edit' | 'remove')=> void,
  eventIndex      : number,
  isPastEvent     : boolean | undefined,
  isToday         : boolean | undefined,
  isNextEvent     : boolean | undefined,
};

const CalendarEventCard = ({
  event, className, editionMode, eventIndex, onChangeEvent, isPastEvent, isToday, isNextEvent,
} : CalendarEventCardProps) : React.ReactNode => {
  const labelClassName = labelToClassName.get(event.eventType) ?? '';

  // TODO: comment out after the demo
  // let outcomeValue = event.outcomeValue ?? '';
  // if (isNumberString(outcomeValue)) {
  //   outcomeValue = `${(parseFloat(outcomeValue) * 100).toFixed(2)}%`;
  // }

  return (
    isToday
      ? <p className = {'timeline__item__label'}>{event.eventType}</p>
      : (
        <div className = {`timeline__item timeline__item${labelClassName} ${className ?? ''} 
        ${isPastEvent && 'timeline__item--passed'} ${isNextEvent && 'timeline__item--nextEvent'}`}
        >
          {editionMode && (
            <Space className = {'timeline__item__actions'}>
              <Button
                size = {'small'}
                icon = {<EditFilled />}
                type = {'primary'}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'edit')}
              />

              <Button
                size = {'small'}
                icon = {<DeleteFilled />}
                type = {'primary'}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'remove')}
              />
            </Space>
          )}

          {isNextEvent && <p className = {'timeline__item__value'}>Next Event</p>}

          <p className = {'timeline__item__label'}>{event.eventType}</p>

          <p className = {'timeline__item__value'}>
            {(event.eventType === EventTypesEnum.Coupon as string || event.eventType === EventTypesEnum.Autocall as string)
              ? (
                <>
                  {isPastEvent ? 'Payment: ' : 'Potential flow: '}

                  {event.outcomeValue ?? ''}
                </>
              )
              : null}
          </p>
        </div>
      )
  );
};

export {CalendarEventCard};
