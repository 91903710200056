import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {Button, Table} from 'antd';
import type {GetProps,
  Input, TableColumnsType} from 'antd';


import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import {CustomSelect} from '../../../Shared/CustomSelect/CustomSelect';
import {CustomTable} from '../../../Shared/CustomTable/CustomTable';
import {myFetch} from '../../../config/api';
import {ProductListPageViewEnum} from '../../../data/enums/lifecycle';
import {productsListData as productsListDataJson} from '../mockData/mockData';

import {generateGroupedListColumns, productsColumns, tradesListcolumns} from './ProductColumns';
import {
  groupByAttribute, groupByEventDate, groupByValueRange
} from './utils';

import type {ProductGroupedByAttribute} from './utils';
import type {CustomTableProps} from '../../../Shared/CustomTable/CustomTable';
import type {ProductListItem} from '../../../data/ProductType';
import type {TradeDataType} from '../../../data/TradesTypes';

const ITEMS_PER_PAGE = 30;

dayjs.extend(isBetween);

type ProductColumnType = TableColumnsType<ProductListItem> | TableColumnsType<ProductGroupedByAttribute>;
type ProductListType = ProductListItem[] | ProductGroupedByAttribute[];
type SearchProps = GetProps<typeof Input.Search>;
type TableDataProps = {
  view     : ProductListPageViewEnum,
  products : ProductListType,
  columns  : ProductColumnType,
};

type GetProductResponseType = {
  products   : ProductListItem[],
  totalCount : number,
};

const ProductsList = () : React.ReactNode => {
  const [searchValue, setSearchValue] = useState('');
  const [productItemList, setProductListdata] = useState<ProductListItem[]>(productsListDataJson);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [totalProducts, setTotalProducts] = useState(productItemList.length);

  const [tableData, setTableData] = useState<TableDataProps>({
    view     : ProductListPageViewEnum.DEFAULT,
    products : [],
    columns  : productsColumns(productItemList),
  });
  const navigate = useNavigate();

  const fetchProducts = (page : number, limit : number) : void => {

    setLoadingItems(true);
    myFetch('GET', `/products?page=${page.toString()}&limit=${limit.toString()}`)
      .then((response) : void => {
        const fetchedProducts = response as GetProductResponseType;
        const {
          products, totalCount,
        } = fetchedProducts;
        setProductListdata(products);
        setTotalProducts(totalCount);
      })
      .catch((e : unknown) : void => console.error(e))
      .finally(() => {
        setLoadingItems(false);
      });
  };

  useEffect(() => {
    // fetchProducts(1, 40); TODO: Will comment this out after the Demo
  }, []);


  /**
   * Groups a list of products by client name or seller extrated from the trades
   */
  const groupProductListByTradeParam = (list : ProductListItem[], attribute : keyof TradeDataType) : ProductGroupedByAttribute[] => {
    const result : ProductGroupedByAttribute[] = [];
    list.forEach((product) => {
      product.trades.forEach((trade) => {
        let found = result.find((item) => item.groupName === trade[attribute]);
        if (!found) {
          found = {
            id        : trade[attribute] as string,
            groupName : trade[attribute] as string,
            products  : [],
          };
          result.push(found);
        }
        const filteredProduct = {
          ...product,
          trades : product.trades.filter((t) => t[attribute] === trade[attribute]),
        };

        // Check if the product with the same ISIN already exists in the group
        if (!found.products.find((p) => p.isin === filteredProduct.isin)) {
          found.products.push(filteredProduct);
        }
      });
    });
    return result;
  };

  const expandedTradesRowRender = (trades : ProductListItem['trades']) : React.ReactNode => (
    trades.length
      ? (
        <Table
          key = {tableData.view}
          columns = {tradesListcolumns}
          className = {'expanded__table'}
          dataSource = {trades}
          rowKey = {(record) : string => record.id.toString()}
          pagination = {false}
          id = {'expanded__table__inner__table--2'}
          rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          onRow = {(record) => ({onClick : () : void => navigate(`/trades/${record.id.toString()}`)})}
        />
      )
      : <span className = {'no-content'}>No trades</span>
  );

  const expandedProductsRowRender = (productsList : ProductListItem[]) : React.ReactNode => (
    productsList.length
      ? (
        <Table
          key = {tableData.view}
          columns = {productsColumns(productsList)}
          className = {'expanded__table'}
          dataSource = {productsList}
          rowKey = {(record) : number => record.id}
          rowClassName = {(_, index) : string => (index % 2 === 0 ? 'table-row-even' : 'table-row-odd')}
          expandable = {{
            expandedRowRender : (record) : React.ReactNode => expandedTradesRowRender(record.trades),
            expandIcon        : ({
              expanded, onExpand, record,
            }) : React.ReactNode => (
              <Button
                type = {'text'}
                icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                onClick = {(e) : void => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ),
          }}
          pagination = {productsList.length <= 6
            ? false
            : {
              position        : ['bottomCenter'],
              defaultPageSize : 6,
            }}
          onRow = {(record) => ({onClick : () : void => navigate(`/products/${record.id.toString()}`)})}
        />
      )
      : <span className = {'no-content'}>No Products</span>
  );

  const changeDisplayView = (selectedView : ProductListPageViewEnum) : void => {
    let productsList : ProductListType = [];
    let viewColumns : ProductColumnType = [];
    switch (selectedView) {
      case ProductListPageViewEnum.DEFAULT:
        productsList = productItemList;
        viewColumns = productsColumns(productItemList);
        break;
      case ProductListPageViewEnum.BY_CLIENT:
        productsList = groupProductListByTradeParam(productItemList, 'clientName');
        viewColumns = generateGroupedListColumns('Client');
        break;
      case ProductListPageViewEnum.BY_SELLER:
        productsList = groupProductListByTradeParam(productItemList, 'sellerName');
        viewColumns = generateGroupedListColumns('Seller');
        break;
      case ProductListPageViewEnum.BY_PRODUCT_TYPE:
        productsList = groupByAttribute(productItemList, 'productType');
        viewColumns = generateGroupedListColumns('Product Type');
        break;
      case ProductListPageViewEnum.BY_ISSUER:
        productsList = groupByAttribute(productItemList, 'issuerShortName');
        viewColumns = generateGroupedListColumns('Issuer');
        break;
      case ProductListPageViewEnum.BY_VALORIZATION:
        productsList = groupByValueRange(productItemList, 'valorisation');
        viewColumns = generateGroupedListColumns('Valorisation Level');
        break;
      case ProductListPageViewEnum.BY_NEXT_EVENT:
        productsList = groupByEventDate(productItemList, 'nextEventDate');
        viewColumns = generateGroupedListColumns('Next Event Date');
        break;
      default:
        break;
    }

    setTableData({
      view     : selectedView,
      columns  : viewColumns,
      products : productsList,
    });
  };

  useEffect(() => {
    if (!productItemList.length) {
      return;
    }
    changeDisplayView(tableData.view);
  }, [productItemList]);

  const onSearch : SearchProps['onSearch'] = (searchText) : void => {
    const {view} = tableData;
    let searchedProducts = productsListDataJson;
    let tradeParamGroupedProducts : ProductGroupedByAttribute[] = [];

    setSearchValue(searchText);

    if (searchText) {

      searchedProducts = productsListDataJson.filter(
        (product) => product.name.toLowerCase().includes(searchText.toLowerCase())
          || product.isin.toLowerCase().includes(searchText.toLowerCase())
          || product.productType.toLowerCase().includes(searchText.toLowerCase())
          || product.issuerShortName.toLowerCase().includes(searchText.toLowerCase())
          || product.trades.some((t) => t.clientName.toLowerCase().includes(searchText.toLowerCase()))
          || product.trades.some((t) => t.sellerName.toLowerCase().includes(searchText.toLowerCase()))
      );

      if (view === ProductListPageViewEnum.BY_CLIENT || view === ProductListPageViewEnum.BY_SELLER) {
        const attribute = view === ProductListPageViewEnum.BY_CLIENT ? 'clientName' : 'sellerName';
        tradeParamGroupedProducts = groupProductListByTradeParam(searchedProducts, attribute).filter(
          (product) => product.groupName.toLowerCase().includes(searchText.toLowerCase())
        );
      }
    }

    switch (view) {
      case ProductListPageViewEnum.DEFAULT:
        setTableData({
          ...tableData,
          products : searchedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_CLIENT:
        setTableData({
          ...tableData,
          products : tradeParamGroupedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_SELLER:
        setTableData({
          ...tableData,
          products : tradeParamGroupedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_VALORIZATION:
        setTableData({
          ...tableData,
          products : groupByValueRange(searchedProducts, 'valorisation'),
        });
        break;
      case ProductListPageViewEnum.BY_NEXT_EVENT:
        setTableData({
          ...tableData,
          products : groupByEventDate(searchedProducts, 'nextEventDate'),
        });
        break;
      case ProductListPageViewEnum.BY_ISSUER:
        setTableData({
          ...tableData,
          products : groupByAttribute(searchedProducts, 'issuerShortName'),
        });
        break;
      case ProductListPageViewEnum.BY_PRODUCT_TYPE:
        setTableData({
          ...tableData,
          products : groupByAttribute(searchedProducts, 'productType'),
        });
        break;

      default:
        break;
    }
  };

  const tableProps : Pick<CustomTableProps<ProductListItem>, 'actions' | 'CustomComponent' | 'search'> = {
    actions : [
      {
        title   : 'Add Product',
        onClick : () : void => navigate('/products/new'),
        variant : 'primary',
      },
    ],
    CustomComponent : (<CustomSelect
      defaultValue = {ProductListPageViewEnum.BY_NEXT_EVENT as string}
      selectedValue = {tableData.view as string}
      options = {Object.values(ProductListPageViewEnum)}
      label = {`Group by ${tableData.view}`}
      onChange = {(val) : void => {
        changeDisplayView(val as ProductListPageViewEnum);
        setSearchValue('');
      }}
    />),
    search : {
      searchValue,
      onSearch,
    },
  };

  const {
    columns, products, view,
  } = tableData;

  return (
    <div className = {'products_list_container'}>
      {view === ProductListPageViewEnum.DEFAULT
        ? (
          <CustomTable<ProductListItem>
            key = {view}
            loading = {loadingItems}
            columns = {columns as TableColumnsType<ProductListItem>}
            dataList = {products as ProductListItem[]}
            {...tableProps}
            rowKey = {(record) : number => record.id}
            colsTopApplyDateFilter = {['issueDate', 'maturityDate', 'nextEventDate']}
            colsTopApplySeachFilter = {['isin', 'name']}
            sticky = {true}
            expandable = {{
              expandedRowRender : (record) : React.ReactNode => expandedTradesRowRender(record.trades),
              expandIcon        : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ),
            }}
            pagination = {products.length <= ITEMS_PER_PAGE
              ? false
              : {
                position        : ['bottomCenter'],
                defaultPageSize : ITEMS_PER_PAGE,
              }}
            onRow = {(record) => ({onClick : () : void => navigate(`/products/${record.id.toString()}`)})}
          />
        )
        : (
          <CustomTable<ProductGroupedByAttribute>
            key = {view}
            loading = {loadingItems}
            columns = {columns as TableColumnsType<ProductGroupedByAttribute>}
            dataList = {products as ProductGroupedByAttribute[]}
            {...tableProps}
            rowKey = {(record) : string => record.id.toString()}
            sticky = {true}
            expandable = {{
              expandedRowRender : (record) : React.ReactNode => expandedProductsRowRender(record.products),
              expandIcon        : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    onExpand(record, e);
                  }}
                />
              ),
              expandedRowClassName : () : string => 'expanded__table-row',
              expandRowByClick     : true,
            }}
            pagination = {products.length <= ITEMS_PER_PAGE
              ? false
              : {
                position        : ['bottomCenter'],
                defaultPageSize : ITEMS_PER_PAGE,
              }}
          />
        )}
    </div>
  );
};

export {ProductsList};
