// GLOBALS

export const goldenYellow = '#ffa540';
export const expertGreen = '#00252d';
export const organicBlue = '#006a99';
export const dynamicOrange = '#ff5f45';
export const cleverPurple = '#25307f';
export const lightTextColor = '#fdfcfc';
export const primary500 = '#007e99';
export const greyColor = '#9e9e9e';
export const whiteColor = '#ffffff';
export const greenColor = '#52c41a';
export const redColor = '#f5222d';
