import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import {Button, Flex} from 'antd';

import './MainTitle.scss';

type MainTitlePropsType = {
  text ?             : string,
  style ?            : React.CSSProperties,
  className ?        : string,
  showReturnButton ? : boolean,
  prefix ?           : React.ReactNode,
  suffix ?           : React.ReactNode,
};


export const MainTitle = ({
  prefix, suffix, text, style, className, showReturnButton,
} : MainTitlePropsType) : React.ReactNode => {
  const navigate = useNavigate();
  return (
    <Flex className = {'main_title_wrap'} align = {'center'} justify = {'flex-start'}>

      {showReturnButton
      && (
        <Button
          type = {'text'}
          icon = {<ArrowLeftOutlined style = {{fontSize : '20px'}} />}
          style = {{
            fontSize  : '16px',
            color     : 'white',
            flexBasis : '50px',
            transform : 'translateX(-6px)',
          }}
          onClick = {() : void => navigate(-1)}
        />
      )}

      {prefix && <div className = {'prefix-wrap'}>{prefix}</div>}

      <h1 style = {style} className = {`main_page_title ${showReturnButton && 'main_page_title_leftMargin'} ${className ?? ''}`}>
        {text}
      </h1>

      {suffix && <div className = {'suffix-wrap'}>{suffix}</div>}

    </Flex>
  );
};
