export type CurrencyNameType = 'EUR' | 'USD' | 'GBP' | 'CHF' | 'CAD' | 'AUD' | 'JPY' | 'INR' | 'ILS';

type CurrencyLocaleMapType = {
  [key in CurrencyNameType]: { locale: string; symbol: string };
};

export const CurrencyLocaleMap: CurrencyLocaleMapType = {
  EUR: { locale: 'fr-FR', symbol: '€' },
  USD: { locale: 'en-US', symbol: '$' },
  GBP: { locale: 'en-GB', symbol: '£' },
  CHF: { locale: 'fr-CH', symbol: 'CHF' },
  CAD: { locale: 'en-CA', symbol: 'C$' },
  AUD: { locale: 'en-AU', symbol: 'A$' },
  JPY: { locale: 'ja-JP', symbol: '¥' },
  INR: { locale: 'en-IN', symbol: '₹' },
  ILS: { locale: 'he-IL', symbol: '₪' },
}