import React, {useEffect, useRef, useState} from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';

import {CloseOutlined, FileOutlined, LoadingOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
  message
} from 'antd';
import type {FormProps, InputRef, UploadProps} from 'antd';


import dayjs from 'dayjs';

import {CalendarEvents} from '../../../Shared/CalendarEvents/CalendarEvents';
import {CurrencyInput} from '../../../Shared/CurrencyInput/CurrencyInput';
import {MainTitle} from '../../../Shared/MainTitle/MainTitle';
import {myFetch} from '../../../config/api';
import {
  eventTypesList
} from '../../../data/ProductType';
import {EditProductPageRoute} from '../../../data/Routes';
import {CustomProductAttrEnum, EventStatuslEnum, EventTypesEnum} from '../../../data/enums/lifecycle';
import {dbCurrencyData, dbIssuersList, dbProductSubTypeMapping} from '../ProductPage/data';

import {AddUnderlyingsForm} from './AddUnderlyingsForm/AddUnderlyingsForm';
import {useAutoCreateEvent} from './autoCreateEventHook';
import {productFields} from './productInputFields';

import type {EquityLikeFormType} from './AddUnderlyingsForm/AddUnderlyingsForm';
import type {
  ProductEvent, ProductField, ProductFormFieldType, SingleProductType
} from '../../../data/ProductType';

import './CreateProductPage.scss';


const CreateProductPage = () : React.ReactNode => {
  const [form] = Form.useForm<ProductFormFieldType>();
  const inputEventDateRef = useRef<HTMLInputElement>(null);
  const [maturityDateFormatted, setMaturityDateFormatted] = useState<string>('');
  const [currentEventIndex, setCurrentEventIndex] = useState<number | null>(null);
  const maturityLength = Form.useWatch<string>('maturityLength', form);
  const issueDate = Form.useWatch<string>('issueDate', form);
  const productType = Form.useWatch<string>('productType', form);
  const productSubType = Form.useWatch<string>('productSubType', form);
  const frequency = Form.useWatch<string>('frequency', form);
  const couponFloor = Form.useWatch<string>('couponFloor', form);
  const autocallFloor = Form.useWatch<string>('autocallFloor', form);
  const initialFixingDate = Form.useWatch<string>('initialFixingDate', form);
  const [items, setItems] = useState<string[]>(eventTypesList);
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const defaultCustomFieldValues : ProductFormFieldType['fields'] = [
    {
      id          : 1,
      customKey   : '',
      customValue : '',
    },
  ];
  const [customFields, setCustomFields] = useState<ProductFormFieldType['fields']>(defaultCustomFieldValues);
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);
  const [calendarEventForm, setCalendarEventForm] = useState<ProductFormFieldType['events'][0]>({
    id            : 1,
    eventType     : '',
    paymentDate   : '',
    valuationDate : '',
    outcomeValue  : '',
    status        : 'Future',
  });

  const [product, setProduct] = useState<SingleProductType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onNameChange = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setName(event.target.value);
  };

  const addItem = (e : React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) : void => {
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    inputRef.current?.focus();
  };

  const {productId} = useParams<{productId : string}>();

  const isEditing = Boolean(useMatch({path : EditProductPageRoute.path}));

  const getProduct = () : void => {
    if (!productId) {
      return;
    }
    setIsLoading(true);

    myFetch('GET', `/products/${productId.toString()}`)
      .then((response) : void => {
        const fetchedProduct = response as SingleProductType;
        setProduct(fetchedProduct);
      })
      .catch((e : unknown) : void => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isEditing) {
      getProduct();
    }
  }, []);

  useEffect(() => {
    if (isEditing && product) {
      // TODO: fill the other fields
      form.setFieldsValue({
        isin              : product.isin,
        name              : product.name,
        issuerId          : product.issuerId,
        productType       : product.productType,
        status            : product.status,
        issueDate         : dayjs(product.issueDate).format('YYYY-MM-DD'),
        initialFixingDate : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
        termSheet         : product.termSheet,
      });

      setCustomFields(product.fields ?? []);

      // Set basic calendar events and the product event
      setCalendarEvents(product.events ? [
        ...product.events,
        ...[
          {
            id            : Date.now(),
            status        : dayjs(product.maturityDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Maturity',
            paymentDate   : dayjs(product.maturityDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.maturityDate).format('YYYY-MM-DD'),
          },
          {
            id            : Date.now(),
            status        : dayjs(product.issueDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Issue',
            paymentDate   : dayjs(product.issueDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.issueDate).format('YYYY-MM-DD'),
          },
          {
            id            : Date.now(),
            status        : dayjs(product.initialValuationDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Initial Fixing',
            paymentDate   : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
          },

          // Add today event marker
          {
            id            : Date.now(),
            status        : 'Future',
            eventType     : 'Today',
            paymentDate   : dayjs().format('YYYY-MM-DD'),
            valuationDate : dayjs().format('YYYY-MM-DD'),
          },
        ],
      ]
        : []);
    }
  }, [product]);

  useEffect(() => {
    // When the product type is changed, reset the product sub type
    form.setFieldsValue({productSubType : productType === CustomProductAttrEnum.Custom as string ? CustomProductAttrEnum.Custom : ''});
  }, [productType]);


  useEffect(() => {
    // Create a new event when the maturity, or issue date changes
    const newEventsState = [...calendarEvents];

    if (maturityLength) {
      const today = new Date();
      const maturityDateAsDate = new Date(today.setMonth(today.getMonth() + Number(maturityLength)));
      const dateFormatted = dayjs(maturityDateAsDate).format('YYYY-MM-DD');
      setMaturityDateFormatted(dateFormatted);
      const eventIndex = newEventsState.findIndex((event) => event.eventType === EventTypesEnum.MaturityDate as string);

      const maturityEvent = {
        id            : calendarEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.MaturityDate,
        paymentDate   : maturityDateFormatted,
        valuationDate : maturityDateFormatted,
      };

      // eslint-disable-next-line no-negated-condition
      if (eventIndex !== -1) {
        newEventsState[eventIndex] = maturityEvent;
      } else {
        newEventsState.push(maturityEvent);
      }
    }

    if (issueDate) {
      const eventIndex = newEventsState.findIndex((event) => event.eventType === EventTypesEnum.IssueDate as string);

      const issueDateEvent = {
        id            : calendarEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.IssueDate,
        paymentDate   : dayjs(issueDate).format('YYYY-MM-DD'),
        valuationDate : dayjs(issueDate).format('YYYY-MM-DD'),
      };

      // eslint-disable-next-line no-negated-condition
      if (eventIndex !== -1) {
        newEventsState[eventIndex] = issueDateEvent;
      } else {
        newEventsState.push(issueDateEvent);
      }
    }

    if (initialFixingDate) {
      const eventIndex = newEventsState.findIndex((event) => event.eventType === EventTypesEnum.InitialFixing as string);

      const initialFixingDateEvent = {
        id            : calendarEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.InitialFixing,
        paymentDate   : dayjs(initialFixingDate).format('YYYY-MM-DD'),
        valuationDate : dayjs(initialFixingDate).format('YYYY-MM-DD'),
      };

      // eslint-disable-next-line no-negated-condition
      if (eventIndex !== -1) {
        newEventsState[eventIndex] = initialFixingDateEvent;
      } else {
        newEventsState.push(initialFixingDateEvent);
      }
    }

    setCalendarEvents(newEventsState.sort((left, right) => new Date(left.valuationDate).getTime() - new Date(right.valuationDate).getTime()));
  }, [maturityLength, issueDate, initialFixingDate]);

  const addNewCustomField = () : void => {
    setCustomFields([
      ...customFields,
      ...defaultCustomFieldValues,
    ]);
  };

  const onChangeInputOptions = (paramKey : keyof ProductField, paramValue : string, paramIndex : number) : void => {
    setCustomFields(
      customFields.map((field, index) => {
        if (paramIndex === index) {
          return {
            ...field,
            [paramKey] : paramValue,
          };
        }
        return field;
      })
    );
  };

  const resetEvenForm = () : void => {
    setCalendarEventForm({
      id            : calendarEvents.length + 1,
      eventType     : '',
      paymentDate   : '',
      valuationDate : '',
      outcomeValue  : '',
      status        : 'Future',
    });
  };

  const onAddCalendarEvent = () : void => {
    const {
      eventType, valuationDate,
    } = calendarEventForm;

    if (!eventType || !valuationDate) {
      return;
    }

    // add and sort the events by date
    const updatedCalendarEvents = [
      ...calendarEvents,
      calendarEventForm,
    ];
    setCalendarEvents(updatedCalendarEvents);

    // reset the form inputs
    resetEvenForm();
  };

  const onUpdateCalendarEvent = () : void => {
    setCalendarEvents(calendarEvents.map((event, index) => (index === currentEventIndex ? calendarEventForm : event)));
    setCurrentEventIndex(null);
    resetEvenForm();
  };

  const onChangecalendarEventForm = (key : string, inputValue : string | number) : void => {
    setCalendarEventForm({
      ...calendarEventForm,
      [key] : inputValue,
    });
  };

  const onChangeCalendarEvent = (eventIndex : number, action : 'edit' | 'remove') : void => {
    if (action === 'remove') {
      setCalendarEvents(calendarEvents.filter((_, index) => eventIndex === index));
    } else {
      setCurrentEventIndex(eventIndex);
      setCalendarEventForm(calendarEvents[eventIndex]);
      inputEventDateRef.current?.focus();
    }
  };

  const calculateTotalDuration = (numOfMonths : number) : string => {
    const years = Math.floor(numOfMonths / 12);
    const months = numOfMonths % 12;
    if (years === 0) {
      return `${months.toString()}M`;
    }
    if (months === 0) {
      return `${years.toString()}Y`;
    }
    return `${years.toString()}Y${months.toString()}M`;
  };

  const onAddUnderlying = (equityLikeList : EquityLikeFormType[], underlyingRates : string[]) : void => {
    form.setFieldsValue({
      underlyingRates,
      underlyings : equityLikeList,
    });
  };

  // Set the status of the events based on the current date
  const cleanEvents = (events : ProductEvent[]) : ProductEvent[] => events.map((event) => {
    if (new Date(event.valuationDate).getTime() < new Date().getTime()) {
      event.status = EventStatuslEnum.Past;
    }
    const nextEvent = events.find((e) => new Date(e.valuationDate).getTime() > new Date().getTime());
    if (nextEvent && nextEvent.valuationDate === event.valuationDate) {
      event.status = EventStatuslEnum.NextEvent;
    }
    return event;
  });

  const onFinish : FormProps<ProductFormFieldType>['onFinish'] = (values : ProductFormFieldType) : void => {
    setIsCreating(true);

    let newProduct : Partial<ProductFormFieldType> = {
      isin           : values.isin,
      name           : values.name,
      issuerId       : values.issuerId,
      productType    : values.productType,
      productSubType : values.productSubType,
      currencyRefId  : values.currencyRefId,
      termSheet      : 'https://arxiv.org/pdf/2307.06435', // TODO: use s3 to updload the termsheet
      status         : 'Alive',
      totalDuration  : calculateTotalDuration(values.maturityLength), // '3Y',
      maturityDate   : dayjs(values.issueDate).add(values.maturityLength, 'month')
        .format('YYYY-MM-DD'),
      issueDate            : values.issueDate,
      initialValuationDate : values.initialFixingDate,
      underlyings          : values.underlyings,
      events               : cleanEvents(calendarEvents),
      fields               : customFields.filter((field) => field.customKey && field.customValue),
    };

    if (values.productType !== CustomProductAttrEnum.Custom as string) {
      newProduct = {
        ...newProduct,
        barrierType      : values.barrierType,
        basketType       : '', // TODO: Field no on the UI
        stepDownOverride : '', // TODO: Field no on the UI
        initialBarrier   : values.barrierLevel,
        autocall         : {
          autocallStepdown : values.autocall.autocallStepdown,
          nonCallPeriod    : values.autocall.nonCallPeriod,
        },
        couponBarrier : {
          couponMemoryEffect : values.couponBarrier.couponMemoryEffect,
          couponStepdown     : values.couponBarrier.couponStepdown,
        },
        couponFixed : {
          couponValue   : 100, // TODO: Set the value
          numberPerYear : 100, // TODO: Set the value
        },
        couponVariable : {
          cap           : 100, // TODO: Set the value
          numberPerYear : 100, // TODO: Set the value
          floor         : values.couponFloorValue,
          fixedLeg      : 100, // TODO: Set the value
          floatingLeg   : 100, // TODO: Set the value
        },
      };
    }

    myFetch('POST', '/products', {
      body  : newProduct,
      query : null,
    })
      .then((response) : void => {
        const insertedProduct = response as { productId : number };
        messageApi.open({
          type    : 'success',
          content : 'Product created successfully',
        });
        navigate(`/products/${insertedProduct.productId.toString()}`);
      })
      .catch((e : unknown) : void => {
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : e.message,
          });
        }
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const pickerInputprops : UploadProps = {
    name     : 'termsheet',
    accept   : '.pdf',
    multiple : false,
    onChange : (info) : void => {
      form.setFieldsValue({
        termSheet : info.file,
      });
    },
    onDrop : (e) : void => {
      form.setFieldsValue({
        termSheet : e.dataTransfer.files[0],
      });
    },
  };

  useAutoCreateEvent({
    maturity : maturityDateFormatted,
    frequency,
    calendarEvents,
    setCalendarEvents,
  });

  return (
    <div className = {'createProductPage'}>
      {contextHolder}

      <MainTitle text = {isEditing ? 'Phoenix Autocall on SX5E' : 'Create Product'} showReturnButton = {true} />

      <Form
        name = {'createProduct'}
        className = {'create-product-form'}
        layout = {'vertical'}
        form = {form}
        autoComplete = {'off'}
        requiredMark = {false}
        initialValues = {isEditing
          ? {}
          : {
            productType    : CustomProductAttrEnum.Custom,
            productSubType : CustomProductAttrEnum.Custom,
          }}
        onFinish = {onFinish}
      >

        <Row gutter = {24}>
          <Col md = {productType === CustomProductAttrEnum.Custom as string ? 10 : 7} sm = {24}>

            <div className = {'form__group form__group_general'}>
              <h4 className = {'section__title section__title__background'}>General</h4>

              <div className = {'form__group_content'}>

                <Form.Item
                  label = {'ISIN Code'}
                  name = {'isin'}
                  rules = {[
                    {required : true},
                    {
                      len     : 12,
                      message : 'ISIN must be 12 characters long',
                    },
                  ]}
                >
                  <Input className = {'product-form-input'} placeholder = {'Enter ISIN...'} />
                </Form.Item>

                <Form.Item
                  name = {'name'}
                  label = {'Product Name'}
                  rules = {[{required : true}]}
                >
                  <Input className = {'product-form-input'} placeholder = {'Product Name...'} />
                </Form.Item>

                <Form.Item label = {'Issuer'} name = {'issuerId'} rules = {[{required : true}]}>
                  <Select
                    showSearch
                    placeholder = {'Select Issuer'}
                    className = {'product-select-input'}
                    options = {dbIssuersList.map((issuer) => ({
                      // eslint-disable-next-line id-denylist
                      value : issuer.id,
                      label : <span>{issuer.shortname}</span>,
                    }))}
                  />
                </Form.Item>

                <Form.Item label = {'Product Type'} name = {'productType'} rules = {[{required : true}]}>
                  <Select
                    showSearch
                    placeholder = {'Select Product Type'}
                    className = {'product-select-input'}
                    options = {Object.keys(dbProductSubTypeMapping).map((type) => ({
                      // eslint-disable-next-line id-denylist
                      value : type,
                      label : <span>{type}</span>,
                    }))}
                  />
                </Form.Item>

                <Form.Item label = {'Product Subtype'} name = {'productSubType'} rules = {[{required : true}]}>
                  <Select
                    showSearch
                    placeholder = {'Select subType'}
                    className = {'product-select-input'}
                    options = {
                      productType
                        ? dbProductSubTypeMapping[productType].map((subType) => ({
                          // eslint-disable-next-line id-denylist
                          value : subType,
                          label : <span>{subType}</span>,
                        }))
                        : []
                    }
                  />
                </Form.Item>


                <Flex style = {{width : '100%'}}>
                  <Form.Item
                    label = {'Maturity'}
                    name = {'maturityLength'}
                    rules = {[{required : true}]}
                    style = {{
                      flexBasis   : '100%',
                      marginRight : '10px',
                    }}
                  >
                    <InputNumber
                      className = {'product-form-input'}
                      style = {{width : '100%'}}
                      placeholder = {'0'}
                      suffix = {'months'}
                      min = {1}
                    />
                  </Form.Item>

                  <Form.Item
                    label = {'Currency'}
                    style = {{width : '100%'}}
                    name = {'currencyRefId'}
                    rules = {[{required : true}]}
                  >
                    <Select
                      showSearch
                      style = {{width : '100%'}}
                      placeholder = {'Select Currency'}
                      className = {'product-select-input'}
                      options = {dbCurrencyData.map((currency) => ({
                        // eslint-disable-next-line id-denylist
                        value : currency.id,
                        label : <span>{currency.code}</span>,
                      }))}
                    />
                  </Form.Item>
                </Flex>

                <AddUnderlyingsForm onAddUnderlying = {onAddUnderlying} />

                <Form.Item
                  label = {'Issue'}
                  name = {'issueDate'}
                  rules = {[{required : true}]}
                  // eslint-disable-next-line id-denylist
                  getValueProps = {(v : string) : ({value : dayjs.Dayjs | string}) => ({value : v && dayjs(v)})}
                >
                  <DatePicker
                    className = {'product-form-input'}
                    style = {{width : '100%'}}
                    format = {'DD/MM/YYYY'}
                    allowClear = {false}
                  />
                </Form.Item>

                <Form.Item
                  label = {'Initial Fixing'}
                  name = {'initialFixingDate'}
                  rules = {[{required : true}]}
                  // eslint-disable-next-line id-denylist
                  getValueProps = {(v : string) : ({value : dayjs.Dayjs | string}) => ({value : v && dayjs(v)})}
                >
                  <DatePicker
                    className = {'product-form-input'}
                    style = {{width : '100%'}}
                    format = {'DD/MM/YYYY'}
                    allowClear = {false}
                  />
                </Form.Item>

                <h4 className = {'section__title-topmargin'}>Additional data</h4>

                {customFields.map((customField, index) => (
                  <Space.Compact key = {index} className = {'customField'}>
                    <Input
                      className = {'product-form-input'}
                      value = {customField.customKey}
                      style = {{width : '48%'}}
                      placeholder = {'Enter param name...'}
                      onChange = {(e) : void => onChangeInputOptions('customKey', e.target.value, index)}
                    />

                    <Input
                      className = {'product-form-input'}
                      value = {customField.customValue}
                      style = {{width : '48%'}}
                      placeholder = {'Enter param value...'}
                      onChange = {(e) : void => onChangeInputOptions('customValue', e.target.value, index)}
                    />

                    <Button type = {'dashed'} onClick = {() : void => setCustomFields(customFields.filter((_, i) => i !== index))}>
                      <CloseOutlined />
                    </Button>
                  </Space.Compact>
                ))}


                <Flex className = {'addNewField'} justify = {'flex-end'}>
                  <Button
                    type = {'dashed'}
                    onClick = {addNewCustomField}
                  >
                    Add New Field
                  </Button>
                </Flex>
              </div>
            </div>
          </Col>


          {productType !== CustomProductAttrEnum.Custom as string && (
            <Col className = {'createProductPage__formcol'} md = {8} sm = {24}>
              {Object.keys(productFields).map((fieldGroupKey, groupIndex) => {
                const fieldsGroups = productFields[fieldGroupKey];

                // Hide autocall fields for 'digital' products
                if (productType === 'Digital' && fieldGroupKey === 'autocall') {
                  return null;
                }

                // Hide coupon fields for 'classic autocall' and 'reverse convertible' products subtypes
                const fieldsToHide : string[] = [];
                if (fieldGroupKey === 'coupon') {
                  const isAutocallClassic = productType === 'Autocall' && productSubType === 'Classic';
                  const isAutocallReverse = productType === 'Autocall'
                    && (productSubType === 'Reverse' || productSubType === 'Reverse Convertible');

                  if (isAutocallClassic) {
                    fieldsToHide.push('couponTrigger', 'couponStepdown', 'memoryEffect');
                  } else if (isAutocallReverse) {
                    fieldsToHide.push('couponTrigger', 'couponStepdown');
                  }
                }

                return (
                  <div key = {groupIndex} className = {'form__group'}>
                    <h4 className = {'section__title section__title__background'}>{fieldGroupKey}</h4>

                    <div className = {'form__group_content'}>
                      <Row gutter = {16}>

                        {fieldsGroups.map((field, fieldIndex) => {
                          if (fieldsToHide.includes(field.fieldKey)) {
                            return null;
                          }
                          if (field.fieldType === 'number') {
                            return (
                              <Col key = {fieldIndex} md = {12} sm = {24}>
                                <Form.Item
                                  label = {field.fieldName}
                                  name = {field.fieldKey}
                                  rules = {[{required : true}]}
                                >
                                  <InputNumber
                                    className = {'product-form-input'}
                                    style = {{width : '100%'}}
                                    placeholder = {`Enter ${field.fieldName}`}
                                    suffix = {field.suffix}
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                          if (field.fieldType === 'select') {
                            return (
                              <Col key = {fieldIndex} md = {12} sm = {24}>
                                <Form.Item
                                  label = {field.fieldName}
                                  name = {field.fieldKey}
                                  rules = {[{required : true}]}
                                >
                                  <Select
                                    showSearch
                                    className = {'product-select-input'}
                                    placeholder = {`Select ${field.fieldName}`}
                                    options = {field.options?.map((option) => ({
                                      value : option,
                                      label : option,
                                    })) ?? []}
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }

                          if (field.fieldType === 'toggle') {
                            let fieldLabel = field.fieldName;
                            if (field.fieldKey === 'couponFloor' && couponFloor) {
                              fieldLabel = '';
                            }
                            if (field.fieldKey === 'autocallFloor' && autocallFloor) {
                              fieldLabel = '';
                            }

                            return (
                              <Col
                                key = {fieldIndex}
                                md = {12}
                                sm = {24}
                                style = {{
                                  display    : 'flex',
                                  alignItems : 'center',
                                }}
                              >

                                {field.fieldKey === 'couponFloor' && couponFloor && (
                                  <Form.Item
                                    label = {field.fieldName}
                                    name = {'couponFloorValue'}
                                    rules = {[{required : true}]}
                                  >
                                    <InputNumber
                                      className = {'product-form-input'}
                                      style = {{width : '100%'}}
                                      placeholder = {'Coupon Floor'}
                                      suffix = {'%'}
                                    />
                                  </Form.Item>
                                )}

                                {field.fieldKey === 'autocallFloor' && autocallFloor && (
                                  <Form.Item
                                    label = {field.fieldName}
                                    name = {'autocallFloorValue'}
                                    rules = {[{required : true}]}
                                  >
                                    <InputNumber
                                      className = {'product-form-input'}
                                      style = {{width : '100%'}}
                                      placeholder = {'Coupon Floor'}
                                      suffix = {'%'}
                                    />
                                  </Form.Item>
                                )}

                                <Form.Item
                                  layout = {'horizontal'}
                                  style = {{margin : 0}}
                                  label = {fieldLabel}
                                  name = {field.fieldKey}
                                  rules = {[{required : true}]}
                                >
                                  <Switch checkedChildren = {'On'} unCheckedChildren = {'Off'} />
                                </Form.Item>
                              </Col>
                            );
                          }

                          return (
                            <Col key = {fieldIndex} md = {12} sm = {24}>
                              <Form.Item
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{required : true}]}
                              >
                                <CurrencyInput className = {'product-form-input'} placeholder = {'0'} />
                              </Form.Item>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </div>
                );
              })}

            </Col>

          )}

          <Col className = {'createProductPage__formcol'} md = {productType === CustomProductAttrEnum.Custom as string ? 14 : 9} sm = {24}>

            <div className = {'form__group form__group_calendar'}>
              <h4 className = {'section__title section__title__background'}>Calendar</h4>

              {calendarEvents.length > 0
              && (
                <div className = {'product_timeline_wrapper'}>
                  <CalendarEvents editionMode = {true} events = {calendarEvents} onChangeEvent = {onChangeCalendarEvent} />
                </div>
              )}

              <div className = {'form__group_content'}>


                <p className = {'input_event_type__label'}>Event type & Date</p>

                <Space.Compact className = {'customField'}>

                  <Select
                    showSearch
                    placeholder = {'Select Type...'}
                    style = {{width : '60%'}}
                    className = {'product-select-input'}
                    value = {calendarEventForm.eventType || null}
                    options = {items.map((eventType) => ({
                      // eslint-disable-next-line id-denylist
                      value : eventType,
                      label : <span>{eventType}</span>,
                    }))}
                    dropdownRender = {(menu) : React.ReactElement => (
                      <>
                        {menu}

                        <Divider style = {{margin : '8px 0'}} />

                        <Space.Compact>
                          <Input
                            className = {'product-form-input'}
                            placeholder = {'Enter custom...'}
                            ref = {inputRef}
                            value = {name}
                            onChange = {onNameChange}
                            onKeyDown = {(e) : void => e.stopPropagation()}
                          />

                          <Button type = {'text'} onClick = {addItem}>
                            Add
                          </Button>
                        </Space.Compact>
                      </>
                    )}
                    onChange = {(type : string) : void => onChangecalendarEventForm('eventType', type)}
                  />

                  <DatePicker
                    style = {{width : '40%'}}
                    ref = {inputEventDateRef}
                    className = {'product-form-input'}
                    value = {calendarEventForm.paymentDate ? dayjs(calendarEventForm.paymentDate) : null}
                    onChange = {(_, dateString) : void => onChangecalendarEventForm('paymentDate', dateString as string)}
                  />
                </Space.Compact>

                {[EventTypesEnum.Autocall, EventTypesEnum.Coupon].includes(calendarEventForm.eventType as EventTypesEnum) && (
                  <>
                    <p>Potential Flow</p>

                    <Input
                      min = {0}
                      placeholder = {'0'}
                      className = {'product-form-input'}
                      value = {calendarEventForm.outcomeValue}
                      onChange = {(e) : void => onChangecalendarEventForm('outcomeValue', e.target.value)}
                    />
                  </>
                )}

                <Flex justify = {'flex-end'} className = {'addNewEvent_wrapper'}>
                  <Button
                    type = {'default'}
                    onClick = {() : void => (Number.isInteger(currentEventIndex) ? onUpdateCalendarEvent() : onAddCalendarEvent())}
                  >
                    {Number.isInteger(currentEventIndex) ? 'Update' : 'Add'}
                  </Button>
                </Flex>
              </div>
            </div>

            <h4 className = {'section__title section__title-topmargin'}>Termsheet</h4>

            <Upload.Dragger
              style = {{
                backgroundColor : 'rgba(255,255,255,.4)',
                width           : '100%',
                marginTop       : '16px',
              }}
              {...pickerInputprops}
            >
              <p className = {'ant-upload-drag-icon'}>
                <FileOutlined />
              </p>

              <p className = {'ant-upload-text'}>Click or drag file to this area to upload</p>
            </Upload.Dragger>

            <Flex
              justify = {'flex-end'}
              style = {{
                marginTop : '48px',
                width     : '100%',
              }}
            >
              <Space>
                <Form.Item>
                  <Button size = {'large'} type = {'text'} htmlType = {'reset'}>
                    Reset
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled = {isLoading || isCreating}
                    className = {'submit_product_button'}
                    size = {'large'}
                    type = {'primary'}
                    htmlType = {'submit'}
                  >
                    {isCreating && <Spin size = {'small'} indicator = {<LoadingOutlined spin />} />}

                    {' '}
                    Create
                  </Button>
                </Form.Item>
              </Space>
            </Flex>

          </Col>
        </Row>
      </Form>
    </div>
  );
};
export {CreateProductPage};
