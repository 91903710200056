export enum SolveForEnum {
  reoffer = 'reoffer',
  autocall = 'autocall',
  coupon = 'coupon',
  protection = 'protection',
}

export enum CurrencyEnum {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  ILS = 'ILS',
  CHF = 'CHF',
}

export enum BarrierTypeEnum {
  european = 'european',
  usClose = 'usClose',
  usIntraday = 'usIntraday',
  none = 'none',
}

export enum UnderlyingBasketTypeEnum {
  singleStock = 'Single Stock',
  worstOf = 'Worst Of',
  bestOf = 'Best Of',
  weighted = 'Weighted Average',
}

export enum FieldsNameEnum {
  autocall = 'autocall',
  autocallFloor = 'autocallFloor',
  autocallStepdown = 'autocallStepdown',
  autocallTrigger = 'autocallTrigger',
  barrierLevel = 'barrierLevel',
  barrierType = 'barrierType',
  ccMail = 'ccMail',
  coupon = 'coupon',
  couponFloor = 'couponFloor',
  couponStepdown = 'couponStepdown',
  couponTrigger = 'couponTrigger',
  currency = 'currency',
  downsideLeverage = 'downsideLeverage',
  frequency = 'frequency',
  issuers = 'issuers',
  maturity = 'maturity',
  memory = 'memory',
  nominal = 'nominal',
  nonCall = 'nonCall',
  productType = 'productType',
  realSend = 'realSend',
  reoffer = 'reoffer',
  solveFor = 'solveFor',
  strike = 'strike',
  underlyings = 'underlyings',
  underlyingBasketType = 'underlyingBasketType',
}

export enum QuoteStatusEnum {
  quoting = 'Quoting',
  quoted = 'Quoted',
  failed = 'Failed',
}
