/* eslint-disable no-negated-condition */
import React, { useEffect, useState } from 'react';
import {
  Outlet, useLoaderData, useNavigate, useParams
} from 'react-router-dom';

import { DownOutlined, ExperimentOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import {
  Button,
  Checkbox,
  ConfigProvider,
  Flex,
  Form,
  InputNumber,
  Select,
  Space,
  Switch,
  Table,
  Tooltip
} from 'antd';

import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { defaultIssuerSort, issuersList } from '../../../data/Issuers';
import {
  ProductTypeOptionsList,
  autocallableReverseConvertible,
  classicAutocall,
  hideField,
  reverseConvertible,
  transformPostPriceDataBody
} from '../../../data/ProductType';
import { PricingRoute } from '../../../data/Routes';
import { CurrencyLocaleMap } from '../../../data/currency';
import {
  BarrierTypeEnum, CurrencyEnum, FieldsNameEnum, SolveForEnum, UnderlyingBasketTypeEnum
} from '../../../data/enums/pricing';
import { dynamicOrange, expertGreen, organicBlue } from '../../../styles/colors';

import type { PostPriceBody } from '../../../data/PostPriceBody';
import type { ScheduleData } from '../../../data/Schedule';
import type { Underlying } from '../../../data/Underlying';
import type { CurrencyNameType } from '../../../data/currency';
import type { LabelInValueType } from 'rc-select/lib/Select';

import './../__styles__/responsive.css';
import './../__styles__/styles.scss';

const { Option } = Select;

const PricingFormComponent = () : React.JSX.Element => {
  const { priceId } = useParams();
  const navigate = useNavigate();
  const underlyingList = useLoaderData() as Underlying[];

  const [form] = Form.useForm();
  const [couponFloorDisabled, setCouponFloorDisabled] = useState(true);
  const [savedCouponFloorValue, setSavedCouponFloorValue] = useState(0);
  const [autocallFloorDisabled, setAutocallFloorDisabled] = useState(true);
  const [savedAutocallFloorValue, setSavedAutocallFloorValue] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const productTypeId = Form.useWatch<string>(FieldsNameEnum.productType, form);
  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);
  const maturityValue = Form.useWatch<number>(FieldsNameEnum.maturity, form);
  const frequencyValue = Form.useWatch<number>([FieldsNameEnum.coupon, FieldsNameEnum.frequency], form);
  const nonCallValue = Form.useWatch<number>([FieldsNameEnum.autocall, FieldsNameEnum.nonCall], form);
  const autocallTriggerValue = Form.useWatch<number>([FieldsNameEnum.autocall, FieldsNameEnum.autocallTrigger], form);
  const autocallStepdownValue = Form.useWatch<number>([FieldsNameEnum.autocall, FieldsNameEnum.autocallStepdown], form);
  const autocallFloorValue = Form.useWatch<number>([FieldsNameEnum.autocall, FieldsNameEnum.autocallFloor], form);
  const couponTriggerValue = Form.useWatch<number>([FieldsNameEnum.coupon, FieldsNameEnum.couponTrigger], form);
  const couponStepdownValue = Form.useWatch<number>([FieldsNameEnum.coupon, FieldsNameEnum.couponStepdown], form);
  const couponFloorValue = Form.useWatch<number>([FieldsNameEnum.coupon, FieldsNameEnum.couponFloor], form);
  const memoryEffectValue = Form.useWatch<boolean>([FieldsNameEnum.coupon, FieldsNameEnum.memory], form);
  const barrierTypeValue = Form.useWatch<BarrierTypeEnum>(FieldsNameEnum.barrierType, form);
  const barrierLevelValue = Form.useWatch<number>(FieldsNameEnum.barrierLevel, form);
  const strikeValue = Form.useWatch<number>(FieldsNameEnum.strike, form);
  const realSendValue = Form.useWatch<boolean>(FieldsNameEnum.realSend, form);
  const selectedIssuers = Form.useWatch<string[] | null>(FieldsNameEnum.issuers, form) ?? [];

  let savedReofferValue = 100;
  let savedCouponValue = 3;
  let savedAutocallValue = 100;
  let savedProtectionValue = 70;

  const getPeriodePrefix = () : string => {
    switch (frequencyValue) {
      case 12:
        return 'Year ';
      case 6:
        return 'Semester ';
      case 3:
        return 'Quarter ';
      case 1:
        return 'Month ';
      default:
        return '';
    }
  };

  const nbStep = Math.floor(maturityValue / frequencyValue);
  let scheduleData : ScheduleData[] = [];

  if (nbStep > 0) {
    scheduleData = Array(nbStep)
      .fill(0)
      .map((_, i) : ScheduleData => ({
        key    : i,
        period : getPeriodePrefix() + (i + 1).toString(),
        autocallTriggerValue :
          nonCallValue !== 0 && nonCallValue > i
            ? 'NC'
            : `${Math.max(
              autocallTriggerValue - ((i - nonCallValue) * autocallStepdownValue),
              autocallFloorValue || 0
            ).toFixed(2)}%`,
        couponTriggerValue : `${Math.max(couponTriggerValue - (i * couponStepdownValue), couponFloorValue).toFixed(2)}%`,
      }));
  }

  useEffect(() => {
    if (productTypeId === reverseConvertible.id) {
      form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.nonCall], nbStep - 1);
    }
  }, [nbStep]);

  const onProductTypeChange = (id : string) : void => {
    switch (id) {
      case autocallableReverseConvertible.id:
        form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.couponTrigger], 0);
        break;
      case reverseConvertible.id:
        form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.couponTrigger], 0);
        form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.autocallTrigger], 100);
        form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.nonCall], nbStep - 1);
        break;
      default:

        // No action to take for other product type
    }
  };

  const onBarrierTypeChange = (type : BarrierTypeEnum) : void => {
    if (type === BarrierTypeEnum.none) {
      form.setFieldValue(FieldsNameEnum.barrierLevel, strikeValue);
    }
  };

  const toggleCouponFloorDisabled = () : void => {
    setCouponFloorDisabled(!couponFloorDisabled);
    if (couponFloorDisabled) {
      setSavedCouponFloorValue(form.getFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.couponFloor]) as number);
      form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.couponFloor], 0);
    } else {
      form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.couponFloor], savedCouponFloorValue);
    }
  };

  const toggleAutocallFloorDisabled = () : void => {
    setAutocallFloorDisabled(!autocallFloorDisabled);
    if (!autocallFloorDisabled) {
      setSavedAutocallFloorValue(form.getFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.autocallFloor]) as number);
      form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.autocallFloor], 0);
    } else {
      form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.autocallFloor], savedAutocallFloorValue);
    }
  };

  const scheduleColumn : TableProps<ScheduleData>['columns'] = [
    {
      title     : 'Period',
      dataIndex : 'period',
      key       : 'period',
      align     : 'center',
    },
    {
      title     : 'Autocall Trigger',
      dataIndex : 'autocallTriggerValue',
      key       : 'autocallTriggerValue',
      align     : 'center',
    },
    {
      title     : 'Coupon Trigger',
      dataIndex : 'couponTriggerValue',
      key       : 'couponTriggerValue',
      align     : 'center',
    },
  ];

  const postForm = (formData : PostPriceBody) : void => {
    setIsLoading(true);
    if (autocallFloorDisabled) {
      formData.autocall.autocallFloor = null;
    }
    if (couponFloorDisabled) {
      formData.coupon.couponFloor = null;
    }
    const transformed = transformPostPriceDataBody(formData);
    myFetch('POST', '/price', {
      body  : transformed,
      query : null,
    })
      .then((response) : void => {
        navigate(`${PricingRoute.path}/${response as string}`);
      })
      .catch((e : unknown) : void => console.error(e));
  };

  function labelRender (props : LabelInValueType) : React.JSX.Element {
    const { label } = props;

    return (
      <label
        style = {{
          fontSize : 25,
          color    : 'white',
          cursor   : 'pointer',
        }}
      >
        {label}
      </label>
    );
  }

  const initialValue : PostPriceBody = {
    autocall : {
      autocallFloor    : 0,
      autocallStepdown : 0,
      autocallTrigger  : 100,
      nonCall          : 0,
    },
    barrierLevel : 70,
    barrierType  : BarrierTypeEnum.european,

    ccMail : null,
    coupon : {
      couponFloor    : 0,
      couponStepdown : 0,
      couponTrigger  : 70,
      coupon         : 3,
      frequency      : 12,
      memory         : true,
    },
    currency             : CurrencyEnum.EUR,
    downsideLeverage     : 100,
    issuers              : issuersList.filter((e) => !e.disabled).map((e) : string => e.name),
    maturity             : 36,
    nominal              : 500000,
    productType          : ProductTypeOptionsList[0].value,
    realSend             : false,
    reoffer              : null,
    solveFor             : SolveForEnum.reoffer,
    strike               : 100,
    underlyings          : [],
    underlyingBasketType : 'Single',
  };

  const pricingCard = () : React.JSX.Element => (
    <Flex
      className = {'cardContent'}
      justify = {'space-around'}
      style = {{
        flexWrap  : 'wrap',
        columnGap : 50,
      }}
    >
      <Form.Item
        name = {FieldsNameEnum.ccMail}
        label = {<span className = {'label white'}>CC Mail</span>}
        style = {{ flexGrow : 1 }}
      >
        <Select
          allowClear
          showSearch
          className = {'fieldColor'}
          size = {'middle'}
          mode = {'multiple'}
          optionFilterProp = {'label'}
          placeholder = {'None'}
          popupMatchSelectWidth = {false}
        >
          <Option value = {'arthur.teixeira@aydofinance.com'}>arthur.teixeira@aydofinance.com</Option>

          <Option value = {'pierre-yves.breton@aydofinance.com'}>pierre-yves.breton@aydofinance.com</Option>

          <Option value = {'jordan.sfez@aydofinance.com'}>jordan.sfez@aydofinance.com</Option>

          <Option value = {'axel.legros@aydofinance.com'}>axel.legros@aydofinance.com</Option>

          <Option value = {'elliot.spadone@aydofinance.com'}>elliot.spadone@aydofinance.com</Option>

          <Option value = {'justine.signouret@aydofinance.com'}>justine.signouret@aydofinance.com</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name = {FieldsNameEnum.realSend}
        label = {<span className = {'label white'}>Real send</span>}
        valuePropName = {'checked'}
      >
        <Switch style = {{ backgroundColor : realSendValue ? dynamicOrange : 'grey' }} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button
            type = {'primary'}
            htmlType = {'submit'}
            loading = {isLoading}
            style = {{
              backgroundColor : dynamicOrange,
              width           : '100px',
              height          : '30px',
              fontWeight      : 'bold',
              fontSize        : '16px',
            }}
          >
            {isLoading ? 'Sending' : 'Price'}
          </Button>

          <Button htmlType = {'reset'}>Reset</Button>
        </Space>
      </Form.Item>
    </Flex>
  );

  const resultCard = () : React.JSX.Element => (
    <Flex className = {'cardContent'} justify = {'space-around'}>
      <Button
        size = {'large'}
        type = {'primary'}
        disabled = {false}
        onClick = {() : void => {
          navigate(PricingRoute.path);
          setIsLoading(false);
        }}
      >
        Re-Quote
      </Button>

      <Button
        size = {'large'}
        type = {'primary'}
        htmlType = {'reset'}
        disabled = {false}
        onClick = {() : void => {
          navigate(PricingRoute.path);
          form.resetFields();
          setIsLoading(false);
        }}
      >
        New Quote
      </Button>
    </Flex>
  );

  return (
    <Form
      form = {form}
      className = {'content'}
      name = {'AyDeal'}
      labelAlign = {'left'}
      layout = {'horizontal'}
      size = {'small'}
      colon = {false}
      requiredMark = {false}
      initialValues = {initialValue}
      disabled = {Boolean(priceId)}
      onFinish = {postForm}
    >
      <Flex align = {'center'} style = {{ marginBottom : 16 }}>
        <MainTitle prefix = {<ExperimentOutlined />} text = {'AyDeal'} />

        <ConfigProvider
          theme = {{
            components : {
              Select : {
                selectorBg                : 'rgba(0,0,0,0)',
                optionActiveBg            : 'rgba(255,255,255,0.3)',
                optionSelectedColor       : 'white',
                optionSelectedBg          : 'rgba(255,255,255,0.3)',
                colorText                 : 'white',
                optionFontSize            : 18,
                optionPadding             : 10,
                showArrowPaddingInlineEnd : 30,
              },
            },
          }}
        >
          <Form.Item
            name = {FieldsNameEnum.productType}
            layout = {'horizontal'}
            colon = {false}
            style = {{
              width        : 'fit-content',
              marginBottom : 0,
              marginLeft   : 52,
              marginTop    : 8,
            }}
          >
            <Select
              style = {{ margin : 'unset' }}
              labelRender = {labelRender}
              size = {'large'}
              variant = {'borderless'}
              options = {ProductTypeOptionsList}
              popupMatchSelectWidth = {false}
              suffixIcon = {(
                <DownOutlined
                  style = {{
                    color    : 'white',
                    fontSize : 18,
                  }}
                />
              )}
              dropdownStyle = {{
                backgroundColor : 'rgb(0,37,45)',
                border          : '2px solid white',
                color           : 'white',
              }}
              onChange = {(id : string) : void => onProductTypeChange(id)}
            />
          </Form.Item>
        </ConfigProvider>
      </Flex>

      <Flex className = {'mainContent'} justify = {'space-between'}>
        <Flex className = {'formContent'}>
          <Flex vertical gap = {'middle'}>
            <div className = {'cardWrapper goldenYellowWrapper'}>
              <div className = {'card'}>
                <div className = {'cardHeader goldenYellow'}>
                  <h2>General</h2>
                </div>

                <Flex vertical className = {'cardContent'}>
                  <Form.Item
                    name = {FieldsNameEnum.solveFor}
                    label = {<span className = {'label white'}>Solve for</span>}
                  >
                    <Select
                      className = {'floatRight'}
                      onSelect = {() : void => {
                        form.setFieldValue(FieldsNameEnum.reoffer, savedReofferValue);
                        form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.coupon], savedCouponValue);
                        form.setFieldValue(
                          [FieldsNameEnum.autocall, FieldsNameEnum.autocallTrigger],
                          savedAutocallValue
                        );
                        form.setFieldValue(FieldsNameEnum.barrierLevel, savedProtectionValue);
                        switch (form.getFieldValue(FieldsNameEnum.solveFor)) {
                          case 'reoffer':
                            savedReofferValue = form.getFieldValue(FieldsNameEnum.reoffer) as number;
                            form.setFieldValue(FieldsNameEnum.reoffer, null);
                            break;
                          case 'coupon':
                            savedCouponValue = form.getFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.coupon]) as number;
                            form.setFieldValue([FieldsNameEnum.coupon, FieldsNameEnum.coupon], null);
                            break;
                          case 'autocall':
                            savedAutocallValue = form.getFieldValue([
                              FieldsNameEnum.autocall,
                              FieldsNameEnum.autocallTrigger,
                            ]) as number;
                            form.setFieldValue([FieldsNameEnum.autocall, FieldsNameEnum.autocallTrigger], null);
                            break;
                          case 'protection':
                            savedProtectionValue = form.getFieldValue(FieldsNameEnum.barrierLevel) as number;
                            form.setFieldValue(FieldsNameEnum.barrierLevel, null);
                            break;
                          default:
                        }
                      }}
                    >
                      <Option value = {SolveForEnum.reoffer}>Reoffer</Option>

                      <Option value = {SolveForEnum.coupon}>Coupon</Option>

                      <Option value = {SolveForEnum.autocall}>Autocall</Option>

                      <Option value = {SolveForEnum.protection}>Protection</Option>
                    </Select>
                  </Form.Item>

                  <Flex justify = {'space-between'}>
                    <span className = {'label white'}>Nominal</span>

                    <Space.Compact>
                      <Form.Item
                        name = {FieldsNameEnum.nominal}
                        rules = {[
                          {
                            required : true,
                            message  : 'Please input nominal amount',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder = {'10000'}
                          className = {'fieldBgColor'}
                          controls = {false}
                          min = {10000}
                          step = {5000}
                          formatter = {(v) : string => (v ?? 10000).toString().replace(/\B(?=(?:\d{3})+(?!\d))/gu, ',')}
                        />
                      </Form.Item>

                      <Form.Item name = {FieldsNameEnum.currency}>
                        <Select>
                          {Object.keys(CurrencyLocaleMap).map((currency, i) : React.JSX.Element => (
                            <Option key = {i} value = {currency}>
                              <Tooltip title = {currency}>
                                {CurrencyLocaleMap[currency as CurrencyNameType].symbol}
                              </Tooltip>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Space.Compact>
                  </Flex>

                  <Form.Item
                    name = {FieldsNameEnum.reoffer}
                    label = {<span className = {'label white'}>Reoffer</span>}
                    layout = {'horizontal'}
                    rules = {[
                      {
                        required : solveForValue !== SolveForEnum.reoffer,
                        message  : 'Please input reoffer',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      disabled = {solveForValue === SolveForEnum.reoffer || Boolean(priceId)}
                      min = {1}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.maturity}
                    label = {<span className = {'label white'}>Maturity</span>}
                    rules = {[
                      {
                        required : true,
                        message  : 'Please input maturity',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'Months'}
                      style = {{ width : '65%' }}
                      min = {1}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.underlyings}
                    label = {<span className = {'label white'}>Underlyings</span>}
                    rules = {[
                      {
                        required : true,
                        message  : 'Please select at least one underlying',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      popupMatchSelectWidth = {false}
                      mode = {'multiple'}
                      optionFilterProp = {'label'}
                      placeholder = {'Search for underlyings'}
                      onChange = {() : void => {
                        const underlyingsValue = form.getFieldValue(FieldsNameEnum.underlyings) as string[];
                        if (underlyingsValue.length <= 1) {
                          form.setFieldValue(FieldsNameEnum.underlyingBasketType, UnderlyingBasketTypeEnum.singleStock);
                        } else {
                          form.setFieldValue(FieldsNameEnum.underlyingBasketType, UnderlyingBasketTypeEnum.worstOf);
                        }
                      }}
                    >
                      {underlyingList
                        .map((e, i) => <Option key = {i} value = {e.Ticker} label = {`${e.Name}${e.Ticker}`}>{`${e.Name} (${e.Ticker})`}</Option>)}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.underlyingBasketType}
                    label = {<span className = {'label white'}>Basket type</span>}
                  >
                    <Select disabled>
                      {Object.values(UnderlyingBasketTypeEnum).map((e, i) : React.JSX.Element => (
                        <Option key = {i} value = {e}>
                          {e}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Flex>
              </div>
            </div>

            <div className = {'cardWrapper organicBlueWrapper'}>
              <div className = {'card'}>
                <div className = {'cardHeader organicBlue'}>
                  <h2>Protection</h2>
                </div>

                <Flex vertical className = {'cardContent'}>
                  <Form.Item
                    name = {FieldsNameEnum.barrierType}
                    label = {<span className = {'label white'}>Barrier Type</span>}
                  >
                    <Select onChange = {onBarrierTypeChange}>
                      <Option value = {BarrierTypeEnum.european}>European</Option>

                      <Option value = {BarrierTypeEnum.usClose}>US Close</Option>

                      <Option value = {BarrierTypeEnum.usIntraday}>US Intraday</Option>

                      <Option value = {BarrierTypeEnum.none}>None</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.barrierLevel}
                    label = {<span className = {'label white'}>Barrier Level</span>}
                    hidden = {barrierTypeValue === BarrierTypeEnum.none}
                    rules = {[
                      {
                        required : solveForValue !== SolveForEnum.protection,
                        message  : 'Please input barrier level',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      disabled = {solveForValue === SolveForEnum.protection || Boolean(priceId)}
                      min = {0}
                      onChange = {(e : number | null) : void => {
                        if (e !== null && e > form.getFieldValue('strike')) {
                          form.setFieldValue('strike', e);
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.strike}
                    label = {<span className = {'label white'}>Strike</span>}
                    rules = {[
                      {
                        required : true,
                        message  : 'Please input strike',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      min = {barrierTypeValue === BarrierTypeEnum.none ? 0 : barrierLevelValue}
                      onChange = {(e : number | null) : void => {
                        form.setFieldValue(FieldsNameEnum.downsideLeverage, e !== null ? (100 / e) * 100 : null);
                        if (barrierTypeValue === BarrierTypeEnum.none) {
                          form.setFieldValue(FieldsNameEnum.barrierLevel, e);
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {FieldsNameEnum.downsideLeverage}
                    label = {<span className = {'label white'}>Downside Leverage</span>}
                  >
                    <InputNumber
                      readOnly
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </div>
          </Flex>

          <Flex vertical gap = {'middle'}>
            <div
              className = {'cardWrapper organicBlueWrapper'}
              hidden = {hideField(FieldsNameEnum.autocallTrigger, productTypeId)}
            >
              <div className = {'card'}>
                <div className = {'cardHeader organicBlue'}>
                  <h2>Autocall</h2>
                </div>

                <Flex vertical className = {'cardContent'}>
                  <Form.Item
                    name = {[FieldsNameEnum.autocall, FieldsNameEnum.autocallTrigger]}
                    label = {<span className = {'label white'}>Autocall Trigger</span>}
                    dependencies = {[FieldsNameEnum.solveFor]}
                    rules = {[
                      {
                        required : solveForValue !== SolveForEnum.autocall,
                        message  : 'Please input autocall trigger',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      min = {couponTriggerValue}
                      disabled = {solveForValue === SolveForEnum.autocall}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {[FieldsNameEnum.autocall, FieldsNameEnum.autocallStepdown]}
                    label = {<span className = {'label white'}>Autocall Stepdown</span>}
                    dependencies = {[FieldsNameEnum.autocallTrigger, FieldsNameEnum.couponTrigger, FieldsNameEnum.solveFor]}
                    rules = {[
                      () => ({
                        async validator (_, v) : Promise<void> {
                          if (
                            v
                            <= (autocallTriggerValue - couponTriggerValue + ((nbStep - 1) * couponStepdownValue))
                            / (nbStep - 1)
                          ) {
                            return Promise.resolve();
                          }
                          if (solveForValue === SolveForEnum.autocall) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please adjust'));
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      disabled = {solveForValue === SolveForEnum.autocall}
                    />
                  </Form.Item>

                  <Flex justify = {'space-between'}>
                    <Form.Item
                      name = {[FieldsNameEnum.autocall, FieldsNameEnum.autocallFloor]}
                      label = {<span className = {'label white'}>Autocall Floor</span>}
                    >
                      <InputNumber
                        className = {'fieldBgColor'}
                        suffix = {'%'}
                        controls = {false}
                        style = {{ display : autocallFloorDisabled ? 'none' : 'inline-flex' }}
                        disabled = {autocallFloorDisabled}
                      />
                    </Form.Item>

                    <Switch
                      checked = {!autocallFloorDisabled}
                      style = {{
                        backgroundColor : autocallFloorDisabled ? 'grey' : organicBlue,
                      }}
                      onChange = {() : void => toggleAutocallFloorDisabled()}
                    />
                  </Flex>

                  <Form.Item
                    name = {[FieldsNameEnum.autocall, FieldsNameEnum.nonCall]}
                    label = {<span className = {'label white'}>NonCall</span>}
                    hidden = {hideField(FieldsNameEnum.nonCall, productTypeId)}
                  >
                    <Select>
                      {nbStep > 0
                        ? Array(nbStep)
                          .fill(0)
                          .map((_, i) : React.JSX.Element => (
                            <Option key = {i} value = {i}>
                              {i}
                            </Option>
                          ))
                        : <></>}
                    </Select>
                  </Form.Item>
                </Flex>
              </div>
            </div>

            <div className = {'cardWrapper organicBlueWrapper'}>
              <div className = {'card'}>
                <div className = {'cardHeader organicBlue'}>
                  <h2>Coupon</h2>
                </div>

                <Flex vertical className = {'cardContent'}>
                  <Form.Item
                    name = {[FieldsNameEnum.coupon, FieldsNameEnum.coupon]}
                    label = {<span className = {'label white'}>Coupon</span>}
                    rules = {[
                      {
                        required : solveForValue !== SolveForEnum.coupon,
                        message  : 'Please input coupon',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'% p. a.'}
                      disabled = {solveForValue === SolveForEnum.coupon || Boolean(priceId)}
                      min = {0}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {[FieldsNameEnum.coupon, FieldsNameEnum.frequency]}
                    label = {<span className = {'label white'}>Frequency</span>}
                  >
                    <Select>
                      <Option value = {12}>Annually</Option>

                      <Option value = {6}>Semi-Annually</Option>

                      <Option value = {3}>Quarterly</Option>

                      <Option value = {1}>Monthly</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name = {[FieldsNameEnum.coupon, FieldsNameEnum.memory]}
                    label = {<span className = {'label white'}>Memory Effect</span>}
                    valuePropName = {'checked'}
                    hidden = {hideField(FieldsNameEnum.memory, productTypeId)}
                  >
                    <Switch
                      className = {'floatRight'}
                      style = {{ backgroundColor : memoryEffectValue ? dynamicOrange : 'grey' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {[FieldsNameEnum.coupon, FieldsNameEnum.couponTrigger]}
                    label = {<span className = {'label white'}>Coupon Trigger</span>}
                    hidden = {hideField(FieldsNameEnum.couponTrigger, productTypeId)}
                    rules = {[
                      {
                        required : true,
                        message  : 'Please input coupon trigger',
                      },
                    ]}
                  >
                    <InputNumber
                      className = {'floatRight fieldBgColor'}
                      controls = {false}
                      suffix = {'%'}
                      min = {0}
                      onChange = {(e : number | null) : void => {
                        if (e !== null && e < form.getFieldValue('couponFloor')) {
                          form.setFieldValue('couponFloor', e);
                        }
                        if (e !== null && e > form.getFieldValue('autocallTrigger')) {
                          form.setFieldValue('autocallTrigger', e);
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name = {[FieldsNameEnum.coupon, FieldsNameEnum.couponStepdown]}
                    label = {<span className = {'label white'}>Coupon Stepdown</span>}
                    hidden = {hideField(FieldsNameEnum.couponStepdown, productTypeId)}
                    dependencies = {[FieldsNameEnum.couponTrigger, FieldsNameEnum.barrierLevel, FieldsNameEnum.solveFor]}
                    rules = {[
                      () => ({
                        async validator (_, v) : Promise<void> {
                          if (v <= (couponTriggerValue - barrierLevelValue) / (nbStep - 1) || barrierTypeValue === BarrierTypeEnum.none) {
                            return Promise.resolve();
                          }
                          if (solveForValue === SolveForEnum.protection) {
                            return Promise.resolve();
                          }
                          if (productTypeId === autocallableReverseConvertible.id
                            || productTypeId === reverseConvertible.id
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please adjust'));
                        },
                      }),
                    ]}
                  >
                    <InputNumber className = {'floatRight fieldBgColor'} controls = {false} suffix = {'%'} />
                  </Form.Item>

                  <Flex justify = {'space-between'} hidden = {productTypeId === classicAutocall.id}>
                    <Form.Item
                      name = {[FieldsNameEnum.coupon, FieldsNameEnum.couponFloor]}
                      label = {<span className = {'label white'}>Coupon Floor</span>}
                      dependencies = {[FieldsNameEnum.couponTrigger]}
                      rules = {[
                        () => ({
                          async validator (_, v) : Promise<void> {
                            if (v <= couponFloorValue) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please adjust'));
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        className = {'fieldBgColor'}
                        controls = {false}
                        suffix = {'%'}
                        style = {{ visibility : couponFloorDisabled ? 'hidden' : 'visible' }}
                        disabled = {couponFloorDisabled}
                      />
                    </Form.Item>

                    <Switch
                      style = {{ backgroundColor : couponFloorDisabled ? 'grey' : organicBlue }}
                      checked = {!couponFloorDisabled}
                      onChange = {() : void => toggleCouponFloorDisabled()}
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
          </Flex>

          <Flex vertical>
            <div className = {'scheduleWrapper cleverPurple'}>
              <div className = {'scheduleCard'}>
                <div className = {'cardHeader cleverPurple'}>
                  <h2>Schedule</h2>
                </div>

                <Table
                  size = {'small'}
                  columns = {scheduleColumn}
                  dataSource = {scheduleData}
                  pagination = {false}
                  rowHoverable = {false}
                  rowClassName = {(_, i) : string => (i % 2 === 1 ? 'rowClassname' : 'rowClassnameLight')}
                  scroll = {{
                    scrollToFirstRowOnChange : false,
                    y                        : '56vh',
                  }}
                />
              </div>
            </div>
          </Flex>
        </Flex>

        <Flex vertical className = {'pricingContent'}>
          {priceId
            ? (
              <Outlet
                context = {{
                  issuersCalled : selectedIssuers,
                  solveFor      : solveForValue,
                  frequency     : frequencyValue,
                }}
              />
            )
            : (
              <div className = {'issuersWrapper dynamicOrangeWrapper'}>
                <div className = {'issuersCard'}>
                  <Flex
                    style = {{
                      height       : 35,
                      marginBottom : 15,
                    }}
                  >
                    <div className = {'cardHeader dynamicOrange '}>
                      <h2>Issuers</h2>
                    </div>

                    <Checkbox
                      className = {'selectAllIssuers'}
                      indeterminate = {selectedIssuers.length > 0 && selectedIssuers.length < issuersList.filter((e) => !e.disabled).length}
                      checked = {selectedIssuers.length === issuersList.filter((e) => !e.disabled).length}
                      onClick = {() : void => {
                        if (selectedIssuers.length !== issuersList.filter((e) => !e.disabled).length) {
                          form.setFieldValue(
                            'issuers',
                            issuersList.filter((e) => !e.disabled).map((e) : string => e.name)
                          );
                        } else {
                          form.setFieldValue('issuers', []);
                        }
                      }}
                    >
                      Select All
                    </Checkbox>
                  </Flex>

                  <Flex
                    wrap
                    justify = {'space-around'}
                    style = {{
                      padding   : '0 10px 20px 10px',
                      columnGap : '30px',
                      rowGap    : '25px',
                    }}
                  >
                    {issuersList
                      .sort(defaultIssuerSort)
                      .map((e) : React.JSX.Element => (
                        <Form.Item key = {e.key} name = {'issuers'} style = {{ marginBottom : 0 }}>
                          <div
                            key = {e.key}
                            className = {`issuerWrapper ${selectedIssuers.includes(e.name) && !e.disabled ? 'selectedIssuer' : ''}
                            ${e.disabled ? 'disabledIssuer' : ''}`}
                            onClick = {() : void => {
                              if (!e.disabled) {
                                const issuerList = form.getFieldValue('issuers') as string[];
                                const index = issuerList.indexOf(e.name);
                                const issuers = [...selectedIssuers];
                                if (index > -1) {
                                  issuers.splice(index, 1);
                                } else {
                                  issuers.push(e.name);
                                }
                                form.setFieldValue('issuers', issuers);
                              }
                            }}
                          >
                            <img className = {'issuer'} src = {e.logo} alt = {'Issuer Logo'} />
                          </div>
                        </Form.Item>
                      ))}
                  </Flex>
                </div>
              </div>
            )}

          <div className = {'cardWrapperSubmit dynamicOrangeWrapper'}>
            <div className = {'cardSubmit'}>
              <div className = {'dynamicOrange cardHeader'}>
                <h2>Pricing</h2>
              </div>

              {priceId ? resultCard() : pricingCard()}
            </div>
          </div>
        </Flex>
      </Flex>
    </Form>
  );
};

export const PricingForm = () : React.JSX.Element => (
  <ConfigProvider
    theme = {{
      token : {
        colorPrimary             : dynamicOrange,
        colorBgContainerDisabled : expertGreen,
        colorTextDisabled        : 'white',
      },
      components : {
        Select : {
          selectorBg : 'rgba(255,255,255,0.3)',
        },
        Table : {
          borderColor        : 'unset',
          headerBg           : expertGreen,
          headerColor        : 'white',
          rowSelectedBg      : 'rgba(0, 37, 45, 0.3)',
          rowSelectedHoverBg : 'rgba(0, 37, 45, 0.8)',
          rowHoverBg         : 'rgba(0, 37, 45, 0.8)',
          headerBorderRadius : 0,
        },
      },
    }}
  >
    <PricingFormComponent />
  </ConfigProvider>
);
