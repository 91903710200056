import './EndOfLifeProducts.scss'
import { Button, Col, Divider, Flex, Input, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import { DeleteFilled, EditFilled, SearchOutlined } from '@ant-design/icons'
import type { InputRef, TableColumnType, TableColumnsType, TableProps } from 'antd'
import { useRef, useState } from 'react'
import type { FilterDropdownProps } from 'antd/es/table/interface'

const { Search } = Input

interface DataType {
  key: React.Key
  isin: string
  productName: string
  issuerName: string
  sellerName: string
  bookerName: string
  maturityDate: string
  nextEventDate: string
  publishStatus: string
  worseOff: number
  nextAutocallBarrier: number
  distToAutocall: number
  view: string
}

type NextPeriodType = 'day' | 'week' | 'month'

type DataIndex = keyof DataType

const data = [
  {
    key: '1',
    isin: 'FR001400NMS9',
    productName: 'HSBC Phoenix ENGI FP – Février 2029',
    issuerName: 'HSBC Bank Plc',
    maturityDate: '2024-09-21',
    nextEventDate: '2024-09-20',
    sellerName: 'Ron S. Whiteman',
    bookerName: 'Joseph J. Langley',
    worseOff: 78,
    nextAutocallBarrier: 95,
    distToAutocall: 17,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '2',
    isin: 'XS2329280189',
    productName: 'Classic Autocall sur : VINCI SA',
    issuerName: 'VINCI SA',
    maturityDate: '2024-09-30',
    nextEventDate: '2024-09-29',
    sellerName: 'Burrell Quessy',
    bookerName: 'Freddie E. Detrick',
    worseOff: 35,
    nextAutocallBarrier: 95,
    distToAutocall: 60,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '3',
    isin: 'XS2304430338',
    productName: 'Phoenix Memory (European Barrier Autocallable) Notes linked to RENAULT SA',
    issuerName: 'Barclays Bank PLCc',
    issuerPrice: 14000,
    maturityDate: '2024-10-19',
    nextEventDate: '2024-09-10',
    sellerName: 'Ron S. Whiteman',
    bookerName: 'Freddie E. Detrick',
    worseOff: 95,
    nextAutocallBarrier: 98,
    distToAutocall: 3,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '4',
    isin: 'FRSG000120S5',
    productName: '5Y Phoenix Snowball Worst-of on ASML Holding NV, Broadcom Inc and NVIDIA Corp in EUR Quanto',
    issuerName: 'BNP Paribas Issuance B.V.',
    maturityDate: '2024-10-30',
    nextEventDate: '2024-09-20',
    sellerName: 'Burrell Quessy',
    bookerName: 'Joseph J. Langley',
    worseOff: 83,
    nextAutocallBarrier: 80,
    distToAutocall: -3,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '5',
    isin: 'XS2347892445',
    productName: 'Phoenix Mémoire Action "Veolia" 5 ans',
    issuerName: 'Barclays Bank PLC',
    maturityDate: '2024-06-25',
    nextEventDate: '2024-06-10',
    sellerName: 'Burrell Quessy',
    bookerName: 'Freddie E. Detrick',
    worseOff: 82,
    nextAutocallBarrier: 86,
    distToAutocall: 4,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '6',
    isin: 'CH1139074467',
    productName: 'Phoenix Mémoire Bloomberg Selection France Decrement 50 Points',
    issuerName: 'BNP Paribas Issuance B.V.',
    maturityDate: '2024-10-10',
    nextEventDate: '2024-09-29',
    sellerName: 'Ron S. Whiteman',
    bookerName: 'Garland Deschamps',
    worseOff: 57,
    nextAutocallBarrier: 67,
    distToAutocall: 10,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '7',
    isin: 'CH1129850694',
    productName: '2Y Phoenix Snowball Worst-of on Alphabet Inc, Apple Inc and Daikin Industries Ltd in USD Quanto',
    issuerName: 'BNP Paribas Issuance B.V.',
    maturityDate: '2024-09-28',
    nextEventDate: '2024-09-25',
    sellerName: 'Gabrielle Brochu',
    bookerName: 'Joseph J. Langley',
    worseOff: 80,
    nextAutocallBarrier: 82,
    distToAutocall: 2,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '8',
    isin: 'XS2347833944',
    productName: 'PHOENIX MEMOIRE SCOR MAI 2024',
    issuerName: 'Natixis Structured Issuance SA.',
    maturityDate: '2024-10-12',
    nextEventDate: '2024-10-10',
    sellerName: 'Gabrielle Brochu',
    bookerName: 'Freddie E. Detrick',
    worseOff: 80,
    nextAutocallBarrier: 76,
    distToAutocall: -4,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '9',
    isin: 'XS2385690792',
    productName: 'PHOENIX MEMOIRE STELLANTIS MAI 2024',
    issuerName: 'Natixis Structured Issuance SA',
    maturityDate: '2024-10-29',
    nextEventDate: '2024-10-20',
    sellerName: 'Ron S. Whiteman',
    bookerName: 'Garland Deschamps',
    worseOff: 44,
    nextAutocallBarrier: 56,
    distToAutocall: 12,
    publishStatus: 'Ongoing',
    view: '',
  },
  {
    key: '10',
    isin: 'FR001400BUI61',
    productName: 'Phoenix Mémoire Action "Veolia" 5 ans',
    issuerName: 'Barclays Bank PLC',
    maturityDate: '2028-03-12',
    nextEventDate: '2025-03-13',
    sellerName: 'Ron S. Whiteman',
    bookerName: 'Freddie E. Detrick',
    worseOff: 72,
    nextAutocallBarrier: 58,
    distToAutocall: 18,
    publishStatus: 'Ongoing',
    view: '',
  },
]

const EndOfLifeProducts = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [nextEventDateFilter, setNextEventDateFilter] = useState('1 week')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [products, setProducts] = useState(data)

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: DataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  })

  const legendColors = [
    { color: 'blue', label: 'Maturity date (Upcoming)' },
    { color: 'green', label: 'dist to autocall > 5' },
    { color: 'orange', label: '0 <= dist to autocall  <= 5' },
    { color: 'yellow', label: '-5 <= dist to autocall  < 0' },
  ]

  const nextPeriods: { value: number; key: NextPeriodType }[] = [
    ...[...Array(5).keys()].map((i) => ({ value: i + 1, key: 'day' as NextPeriodType })),
    ...[...Array(3).keys()].map((i) => ({ value: i + 1, key: 'week' as NextPeriodType })),
    ...[...Array(2).keys()].map((i) => ({ value: i + 1, key: 'month' as NextPeriodType })),
  ]

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ISIN',
      dataIndex: 'isin',
      fixed: 'left',
      width: 120,
      ...getColumnSearchProps('isin'),
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      fixed: 'left',
      width: 260,
      ...getColumnSearchProps('productName'),
      sorter: (left, right) => left.productName.localeCompare(right.productName),
    },
    {
      title: 'Issuer Name',
      dataIndex: 'issuerName',
      width: 140,
      ...getColumnSearchProps('issuerName'),
      sorter: (left, right) => left.issuerName.localeCompare(right.issuerName),
    },
    {
      title: 'Seller Name',
      dataIndex: 'sellerName',
      width: 140,
      ...getColumnSearchProps('sellerName'),
    },
    {
      title: 'Booker Name',
      dataIndex: 'bookerName',
      width: 140,
      ...getColumnSearchProps('bookerName'),
    },
    {
      title: 'Maturity Date',
      dataIndex: 'maturityDate',
      width: 120,
      align: 'center',
      sorter: (left, right) => new Date(left.maturityDate).getTime() - new Date(right.maturityDate).getTime(),
    },
    {
      title: 'Next Event Date',
      dataIndex: 'nextEventDate',
      width: 140,
      align: 'center',
      sorter: (left, right) => new Date(left.nextEventDate).getTime() - new Date(right.nextEventDate).getTime(),
    },
    {
      title: 'Next Autocall Barrier',
      dataIndex: 'nextAutocallBarrier',
      width: 160,
      align: 'center',
      sorter: (left, right) => left.nextAutocallBarrier - right.nextAutocallBarrier,
    },
    {
      title: 'Worse Off',
      dataIndex: 'worseOff',
      width: 100,
      align: 'center',
      sorter: (left, right) => left.worseOff - right.worseOff,
    },
    {
      title: 'Dist to Autocall',
      dataIndex: 'distToAutocall',
      width: 160,
      align: 'center',
      sorter: (left, right) => left.distToAutocall - right.distToAutocall,
    },
    {
      title: 'View',
      dataIndex: 'view',
      align: 'center',
      render: () => (
        <Space size={4} direction="vertical">
          <a>
            <Tag color="gold">Shape</Tag>
          </a>
          <a>
            <Tag color="cyan">Lifecycle</Tag>
          </a>
        </Space>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      dataIndex: 'action',
      fixed: 'right',
      width: 70,
      render: () => (
        <Space align="center" size={4}>
          <Button type="default" icon={<EditFilled />} size={'small'} />
          <Button type="default" icon={<DeleteFilled />} size={'small'} />
        </Space>
      ),
    },
  ]

  const addDaysToDate = (periodKey: NextPeriodType, periodLength: number) => {
    const currentDate = new Date()
    if (periodKey === 'day') {
      currentDate.setDate(currentDate.getDate() + periodLength)
    } else if (periodKey === 'week') {
      const millisecondsToAdd = periodLength * 7 * 24 * 60 * 60 * 1000
      currentDate.setTime(currentDate.getTime() + millisecondsToAdd)
    } else {
      currentDate.setMonth(currentDate.getMonth() + periodLength)
    }
    return currentDate
  }

  const getNextMonthLimit = (period: string): number => {
    const periodSplit = period.split(' ')
    const periodLength = parseInt(periodSplit[0])
    const periodKey = periodSplit[1] as NextPeriodType

    return new Date(addDaysToDate(periodKey, periodLength)).getTime()
  }

  const renderFilteredList = (period: string) => {
    setNextEventDateFilter(period)

    const now = new Date().getTime()

    const nextPeriodLimit = getNextMonthLimit(period)
    const updatedList = data.filter((d) => {
      const nextEventDateMilliSeconds = new Date(d.nextEventDate).getTime()
      return nextEventDateMilliSeconds >= now && nextEventDateMilliSeconds < nextPeriodLimit
    })
    setProducts(updatedList)
  }

  const renderLegendItem = (color: string, label: string) => (
    <Tooltip title={label} key={label}>
      <Tag color={color}>{label}</Tag>
    </Tooltip>
  )

  return (
    <div className="endoflife__products_container">
      <Row gutter={8}>
        <Col span={24}>
          <div className="endoflife__product__list">
            <div className="product__list__header">
              <Flex justify="space-between" align="center">
                <h4>MANAGE PRODUCTS</h4>
                <Space>
                  <span>Next event date in:</span>
                  <Select
                    value={nextEventDateFilter}
                    onChange={(value) => renderFilteredList(value)}
                    options={nextPeriods.map((item, i) => ({
                      value: `${item.value} ${item.key}`,
                      label: <span key={i}>{`${item.value} ${item.key}(s)`}</span>,
                    }))}
                  />
                  <Button type="default" onClick={() => setProducts(data)}>
                    Reset
                  </Button>
                </Space>
                <Space>
                  <Search placeholder="Search products" loading={false} />
                  <Button type="primary">ADD PRODUCT</Button>
                  <Button type="primary">ADD SHAPE PRODUCT</Button>
                </Space>
              </Flex>
            </div>
            <Divider style={{ margin: 0 }} />
            <div className="table__legend">
              <Space>{legendColors.map((colorItem) => renderLegendItem(colorItem.color, colorItem.label))}</Space>
            </div>
            <div className="product__list__table">
              <Table
                bordered
                columns={columns}
                rowClassName={(record) => {
                  let rowClassName = ''
                  const nexPeriodLimit = getNextMonthLimit(nextEventDateFilter)
                  const maturityDateMilliSeconds = new Date(record.maturityDate).getTime()
                  if (maturityDateMilliSeconds < nexPeriodLimit) {
                    rowClassName = 'data-row-blue'
                  } else if (record.distToAutocall > 5) {
                    rowClassName = 'data-row-green'
                  } else if (record.distToAutocall >= 0) {
                    rowClassName = 'data-row-orange'
                  } else if (record.distToAutocall >= -5) {
                    rowClassName = 'data-row-yellow'
                  }
                  return rowClassName
                }}
                dataSource={products}
                onChange={onChange}
                showSorterTooltip={{ target: 'sorter-icon' }}
                pagination={{ position: ['bottomRight'], pageSize: 7 }}
                scroll={{ x: 1680 }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export { EndOfLifeProducts }
