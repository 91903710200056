import { CurrencyLocaleMap, CurrencyNameType } from '../../data/currency'
import { InputNumber, InputNumberProps } from 'antd'
import React from 'react'

interface CurrencyInputProps extends Omit<InputNumberProps, 'formatter' | 'parser'> {
  currency?: CurrencyNameType
}

/**
 * A special version of Ant Design's InputNumber that formats and parses the
 * input value as a currency string.
 */
const CurrencyInput: React.FC<CurrencyInputProps> = ({ currency = 'EUR', ...props }) => {
  const suffix = CurrencyLocaleMap[currency].symbol ?? '€'

  return (
    <InputNumber
      style={{ width: '100%' }}
      placeholder='0'
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/(?:,*)/gu, '') ?? ''}
      suffix={suffix}
      min={0}
      {...props}
    />
  )
}

export { CurrencyInput }
