// TODO: This file is temporal and will be removed when the data is fetched from the
import { ProductEventType } from './../../../data/ProductType';

export const productEventsList: ProductEventType[] = [
    {
      key: '1',
      isin: 'DE000A1EWWW0',
      name: 'DWS Invest Top Dividend LD',
      date: '2025-12-12',
      potentialFlow: 0.85,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 80,
      status: 'Alive',
      validate: undefined,
    },
    {
      key: '2',
      isin: 'CE000A1EWWW0',
      name: 'DWS Best Invest Top Dividend LD',
      date: '2024-11-12',
      potentialFlow: 0.92,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 90,
      status: 'Dead',
      validate: undefined,
    },
    {
      key: '3',
      isin: 'BE000A1EWWW0',
      name: 'DWS Top Invest Top Dividend LD',
      date: '2026-09-14',
      potentialFlow: 0.98,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 95,
      status: 'Alive',
      validate: undefined,
    },
    {
      key: '4',
      isin: 'FE000E1EWWW0',
      name: 'TRS Invest Top Dividend LD',
      date: '2025-08-08',
      potentialFlow: 0.88,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 87,
      status: 'Alive',
      validate: undefined,
    },
    {
      key: '5',
      isin: 'XN000A1EWWW0',
      name: 'PMF Best Invest Top Dividend LD',
      date: '2024-12-12',
      potentialFlow: 0.95,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 92,
      status: 'Alive',
      validate: undefined,
    },
    {
      key: '6',
      isin: 'CD000A1EWWW0',
      name: 'VFC Top Invest Top Dividend LD',
      date: '2025-10-10',
      potentialFlow: 0.97,
      termsheet: 'http://arxiv.org/pdf/2307.06435',
      actualFlow: 91,
      status: 'Dead',
      validate: undefined,
    },
  ]