// TODO: Mock data that will be replaced by backend

export const owners = ['Axel Legros', 'Jordan Sfez', 'Pierre Yves', 'Arthur Teixeira', 'Charles Bougeard']

export const businessProviders = ['Alpheys Invest', 'Bondpartners', 'Bridport & Cie sa', 'Tevali']

export const isinList = [
  'XS2842347580',
  'XS2842347599',
  'XS2842347510',
  'XS2842347521',
  'XS2842347532',
  'XS2842347543',
  'XS2842347554',
  'XS2842347565',
  'XS2842347576',
  'XS2842347587',
  'XS2842347598',
  'XS2842347519',
  'XS2842347520',
  'XS2842347531',
  'XS2842347542',
  'XS2842347553',
  'XS2842347564',
]

export const issuerList = [
  'BNP Paribas Financial Markets',
  'Crédit Industriel et Commercial (CIC)',
  'Nomura Bank (Switzerland) Ltd',
  'Societe Generale SA',
  'Natixis SA',
  'CIBC Capital Markets (Europe) S.A.',
  'Barclays Bank Ireland PLC',
  'Morgan Stanley',
  'RBC Capital Markets (Europe) GmbH',
  'Banco Bilbao Vizcaya Argentaria, S.A.',
]

export const clientsList = ['Gérard Bonneville', 'Jacques Lacasse', 'Corette Veilleux', 'Isabelle Lécuyer']
