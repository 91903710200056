type ProductFieldType = {
  fieldName : string,
  fieldKey  : string,
  fieldType : 'number' | 'select' | 'currency' | 'toggle',
  options ? : (string | number)[],
  suffix ?: string,
};

export const productFields : {[key : string] : ProductFieldType[] } = {
  coupon : [
    {
      fieldName : 'Coupon',
      fieldKey  : 'coupon',
      fieldType : 'number',
      suffix    : '% p. a.',
    },
    {
      fieldName : 'Frequency',
      fieldKey  : 'frequency',
      fieldType : 'select',
      options   : ['Annual', 'Semi-Annual', 'Quarterly', 'Monthly'],
    },
    {
      fieldName : 'Coupon Trigger',
      fieldKey  : 'couponTrigger',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'Coupon Stepdown',
      fieldKey  : 'couponStepdown',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'Memory Effect',
      fieldKey  : 'memoryEffect',
      fieldType : 'toggle',
    },
    {
      fieldName : 'Coupon Floor',
      fieldKey  : 'couponFloor',
      fieldType : 'toggle',
      suffix    : '%',
    },
  ],
  autocall : [
    {
      fieldName : 'Autocall Trigger',
      fieldKey  : 'autocallTrigger',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'Autocall Stepdown',
      fieldKey  : 'autocallStepdown',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'NonCall',
      fieldKey  : 'nonCall',
      fieldType : 'select',
      options   : [0, 1, 2],
    },
    {
      fieldName : 'Autocall Floor',
      fieldKey  : 'autocallFloor',
      fieldType : 'toggle',
      suffix    : '%',
    },
  ],
  protection : [
    {
      fieldName : 'Barrier Type',
      fieldKey  : 'barrierType',
      fieldType : 'select',
      options   : ['European', 'US Close', 'US Intraday', 'None'],
    },
    {
      fieldName : 'Barrier Level',
      fieldKey  : 'barrierLevel',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'Strike',
      fieldKey  : 'strike',
      fieldType : 'number',
      suffix    : '%',
    },
    {
      fieldName : 'Downside Leverage',
      fieldKey  : 'downsideLeverage',
      fieldType : 'number',
      suffix    : '%',
    },
  ],
};
