import {CurrencyLocaleMap} from '../data/currency';

import type {CurrencyNameType} from '../data/currency';

const DEFAULT_CURRENCY = 'EUR';

export const parseCurrency = (amount : number, currency : CurrencyNameType = DEFAULT_CURRENCY) : string => {
  if (isNaN(amount)) {
    return 'Invalid number';
  }

  const isKnownCurrency = Object.keys(CurrencyLocaleMap).includes(currency);

  const currencyObj = isKnownCurrency ? CurrencyLocaleMap[currency] : CurrencyLocaleMap[DEFAULT_CURRENCY];

  return new Intl.NumberFormat(currencyObj.locale, {
    style                 : 'currency',
    currency,
    maximumFractionDigits : 2,
  }).format(amount);
};
