/* eslint-disable id-denylist */
import type { ReactNode } from 'react';

import { EventTypesEnum } from './enums/lifecycle';
import { FieldsNameEnum } from './enums/pricing';

import type { PostPriceBody } from './PostPriceBody';
import type { TradeDataType } from './TradesTypes';
import type { CurrencyNameType } from './currency';

export type ProductType = {
  id                : string,
  name              : string,
  transformFunction : (postPriceBody : PostPriceBody)=> PostPriceBody,
  hideFields        : string [],
};

const phoenixAutocall : ProductType = {
  id                : 'phoenixAutocall',
  name              : 'Phoenix Autocall',
  transformFunction : (d) : PostPriceBody => d,
  hideFields        : [],
};

export const classicAutocall : ProductType = {
  id                : 'classicAutocall',
  name              : 'Classic Autocall',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : postPriceBody.autocall.autocallTrigger,
      couponStepdown : postPriceBody.autocall.autocallStepdown,
      couponFloor    : postPriceBody.autocall.autocallFloor,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor],
};

export const autocallableReverseConvertible : ProductType = {
  id                : 'autocallableReverseConvertible',
  name              : 'Autocallable Reverse Convertible',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : 0,
      couponStepdown : 0,
      couponFloor    : 0,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor],
};

export const reverseConvertible : ProductType = {
  id                : 'reverseConvertible',
  name              : 'Reverse Convertible',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCall          : (postPriceBody.maturity / postPriceBody.coupon.frequency) - 1,
      autocallTrigger  : 100,
      autocallStepdown : 0,
      autocallFloor    : 0,
    },
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : 0,
      couponStepdown : 0,
      couponFloor    : 0,
    },
  }),
  hideFields : [
    FieldsNameEnum.nonCall,
    FieldsNameEnum.autocallTrigger,
    FieldsNameEnum.autocallStepdown,
    FieldsNameEnum.autocallFloor,
    FieldsNameEnum.couponTrigger,
    FieldsNameEnum.couponStepdown,
    FieldsNameEnum.couponFloor,
  ],
};

const digital : ProductType = {

  id                : 'digital',
  name              : 'Digital',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCall         : postPriceBody.maturity / postPriceBody.coupon.frequency,
      autocallTrigger : postPriceBody.coupon.couponTrigger,
    },
  }),
  hideFields : [FieldsNameEnum.autocallTrigger],
};

export const ProductTypeList = [
  phoenixAutocall,
  classicAutocall,
  autocallableReverseConvertible,
  reverseConvertible,
  digital,
];

export const ProductTypeOptionsList = ProductTypeList.map((e) => ({
  value : e.id,
  label : e.name,
}));

export const hideField = (fieldName : string, productId : string) : boolean => ProductTypeList
  .find((p) => p.id === productId)
  ?.hideFields
  .includes(fieldName)
  ?? false;

export const transformPostPriceDataBody = (postPriceBody : PostPriceBody) : PostPriceBody => ProductTypeList
  .find((p) => p.id === postPriceBody.productType)
  ?.transformFunction(postPriceBody)
  ?? postPriceBody;

export type SingleProductType = {
  id                    : number,
  isin                  : string,
  name                  : string,
  productType           : string,
  productSubType        : string,
  issuerId              : number,
  issuerShortName       : string,
  issuerFullName        : string,
  issueDate             : Date,
  maturityDate          : Date,
  nextEventDate         : Date,
  initialValuationDate  : Date,
  currency              : CurrencyNameType,
  totalDuration         : string,
  valorisation          : number,
  denomination          : number,
  status                : string,
  nextEventStatus       : string,
  termSheet             : string,
  finalRedemptionValue  : number,
  cummulativeCouponEarn : number,
  coupon                : number,
  autocall              : Autocall,
  couponBarrier         : CouponBarrier,
  couponFixed           : CouponFixed,
  couponVariable        : CouponVariable,
  events ?              : ProductEvent[],
  fields ?              : ProductField[],
  trades                : TradeDataType[],
};

export type ProductField = {
  id          : number,
  customKey   : string,
  customValue : string,
};

export type ProductEvent = {
  id             : number,
  status         : string,
  eventType      : string,
  valuationDate  : string | Date,
  paymentDate    : string | Date,
  outcomeValue ? : string,
};

type Autocall = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  nonCallPeriod    : number,
  initialBarrier   : number,
  stepdown         : number,
  trigger          : number,
  autocallFloor    : number,
  stepDownOverride : string,
};

type CouponBarrier = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  memoryEffect     : boolean,
  initialBarrier   : number,
  stepDown         : number,
  stepDownOverride : string,
};

type CouponFixed = {
  id            : number,
  couponValue   : number,
  numberPerYear : number,
};

type CouponVariable = {
  id            : number,
  numberPerYear : number,
  cap           : number,
  floor         : number,
  fixedLeg      : number,
  floatingLeg   : string,
};

export type ProductListItem = {
  id              : number,
  isin            : string,
  name            : string,
  productType     : string,
  productSubType  : string,
  issuerShortName : string,
  issuerFullName  : string,
  issueDate       : string,
  maturityDate    : string,
  nextEventDate   : string,
  currency        : CurrencyNameType,
  totalDuration   : string,
  valorisation    : number,
  denomination    : number,
  status          : string,
  nextEventStatus : string,
  trades          : TradeDataType[],
};

export type ProductEventType = {
  key           : string,
  isin          : string,
  name          : string,
  date          : string,
  potentialFlow : number,
  termsheet     : string,
  actualFlow    : number,
  status        : string,
  validate      : ReactNode,
};

export type ProductFormFieldType = {
  isin                 : string,
  name                 : string,
  productType          : string,
  productSubType       : string,
  issuerId             : number,
  currencyRefId        : number,
  termSheet            : string,
  status               : string,
  totalDuration        : string,
  nominal              : number,
  reoffer              : number,
  issueDate            : string,
  maturityDate         : string,
  maturityLength       : number,
  initialFixingDate    : string,
  initialBarrier       : number,
  initialValuationDate : string,
  basketType           : string,
  stepDownOverride     : string,
  downsideLeverage     : string,
  denomination         : number,
  nextEventStatus      : string,
  underlyings          : Underlying[],
  underlyingRates      : string[],
  autocallTrigger      : number,
  autocall : {
    autocallStepdown : number,
    nonCallPeriod    : number,
  },
  autocallFloor      : boolean,
  autocallFloorValue : number,
  couponFrequency    : string,
  couponBarrier : {
    couponMemoryEffect : boolean,
    couponStepdown     : number,
  },
  couponFixed ?: {
    couponValue   : number,
    numberPerYear : number,
  },
  couponVariable ?: {
    cap           : number,
    numberPerYear : number,
    floor         : number,
    fixedLeg      : number,
    floatingLeg   : number,
  },
  couponTrigger    : number,
  couponFloor      : boolean,
  couponFloorValue : number,
  barrierType      : string,
  barrierLevel     : number,
  strike           : number,
  events           : ProductEvent[],
  fields           : ProductField[],

};

export type Underlying = {
  newIsin         : boolean,
  isin            : string,
  tickerWithPlace : string,
  ticker          : string,
  companyId       : number,
};

export type CompanyItemType = {
  companyId   : number,
  companyName : string,
  isin        : string,
};

export type CustomParamsType = {
  label      : string,
  paramValue : string,
};

export const eventTypesList = [
  EventTypesEnum.Autocall,
  EventTypesEnum.Coupon,
  EventTypesEnum.InitialFixing,
  EventTypesEnum.IssueDate,
  EventTypesEnum.FinalFixing,
  EventTypesEnum.MaturityDate,
];

