import {useState} from 'react';

import {Tooltip} from 'antd';

import {expertGreen, whiteColor} from '../../styles/colors';

type ClipboardCopyPropTypes = {
  text : string,
};

const ClipboardCopy = ({text} : ClipboardCopyPropTypes) : React.ReactNode => {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <Tooltip
      title = {clicked ? 'Copied' : 'Copy'}
      color = {whiteColor}
      overlayInnerStyle = {{color : expertGreen}}
      className = {'clipboard-text'}
      style = {{
        cursor     : 'pointer',
        transition : 'all 0.1s ease-in-out',
      }}
      onOpenChange = {() : void => setClicked(false)}
    >
      <span onClick = {(e) : void => {
        e.stopPropagation();
        setClicked(true);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.clipboard.writeText(text);
      }}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export {ClipboardCopy};
